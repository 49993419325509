/**
 Copyright SaasTec Labs - 2024. Todos os direitos reservados
 -----
 Arquivo: main.js
 Projeto: SaaSERP2
 Criação do arquivo: 07/02/2022 - 11:24:54
 Autor: SaaSTec Labs
 -----
 Última modificação: 30/05/2022 - 15:45:53
 Modificado por: pedro.rosa
*/
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import i18n from "./i18n";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/corporativo";
import "./styles/global.scss";
import "@grapecity/wijmo.styles/wijmo.css";
import VueScreenSize from "vue-screen-size";
import wso2 from "@saastec/saastec-wso2-service";
import VueDragscroll from "vue-dragscroll";
import VueFeather from "vue-feather";
import dbHandler from "./mixins/dbHandler";
import VueKonva from "vue-konva";
import YimoVueEditor from "yimo-vue-editor";
import "@saastec/saastec-multiline-filter/dist/saastec-multiline-filter.css";
import moment from "moment";
import "@/constants.js";
import { version, nm_fonte } from "../package.json";
import VueApexCharts from "vue-apexcharts";

//fontaAwesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBarcode, faKeyboard } from "@fortawesome/free-solid-svg-icons";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "material-design-icons-iconfont/dist/material-design-icons.css";

library.add(faBarcode, faKeyboard, faFilePdf);

Vue.component("FontAwesomeIcon", FontAwesomeIcon);

import MultilineFilter from "@saastec/saastec-multiline-filter";
Vue.component("MultilineFilter", MultilineFilter);

// Componentes locais
import WorkInProgress from "./components/workInProgress";
import TabelaWijmo from "@/components/tabelaWijmo";

Vue.component("WorkInProgress", WorkInProgress);
Vue.component("TabelaWijmo", TabelaWijmo);

import image from "./mixins/image";
import audio from "./mixins/audio";
import video from "./mixins/video";
import masks from "./mixins/masks";
import currency from "./mixins/currency";
import dialogoLocaliza from "./mixins/dialogoLocaliza";
import money from "v-money";
import VueMask from "v-mask";
import autoCompletes from "./mixins/autoCompletes";
import drawerProdutos from "./mixins/drawerProdutos";
import validateForm from "./mixins/validateForm";
import regexTable from "./mixins/regexTable";

import unmask from "./mixins/unmask/unmask";
import validate from "./mixins/validate/validate";

import util from "./mixins/util/util";

Vue.use(wso2, {
  router: router,
  tokenEndpoint: process.env.VUE_APP_TOKEN_ENDPOINT,
  serviceUrl: process.env.VUE_APP_SERVICE_URL,
  criptoUrl: process.env.VUE_APP_CRIPTO_URL,
  token: process.env.VUE_APP_TOKEN_AUTH,
  mode: process.env.NODE_ENV,
  app: process.env.VUE_APP_CD_SIST,
  vsis: version,
  nm_fonte,
});
Vue.use(VueFeather);
Vue.use(VueScreenSize);
Vue.use(VueDragscroll);
Vue.use(VueKonva);
Vue.use(money, { precision: 2 });
Vue.use(VueMask);
Vue.use(moment);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

let pt = {
  aligncenter: "Alinhar ao centro",
  alignleft: "Alinhar a esquerda",
  alignright: "Alinhar a direita",
  bgcolor: "Cor de fundo",
  bold: "Negrito",
  cancel: "Cancelar",
  clearLocation: "Clear",
  columns: "Colunas",
  dynamicMap: "Mapa dinâmico",
  emotion: "Emotions",
  fontfamily: "Fonte",
  fontsize: "Tamanho da fonte",
  forecolor: "Cor da fonte",
  fullscreen: "Tela cheia",
  head: "Cabeçalho",
  height: "Altura",
  img: "Imagem",
  eraser: "Borracha",
  insertcode: "Iserir código",
  italic: "Itálico",
  langDynamicOneLocation: "Apenas um produto por localização dinâmica",
  link: "Inserir link",
  linkTarget: "Modo aberto",
  loading: "Carregando...",
  loacation: "Localização",
  openLink: "Abrir link",
  orderlist: "Lista ordenada",
  quote: "Aspas",
  redo: "Refazer",
  rows: "Colunas",
  searchLocation: "Buscar",
  source: "Código em HTML",
  strikethrough: "Tachado",
  submit: "Enviar",
  table: "Tabela",
  text: "Texto",
  underline: "Sublinhado",
  undo: "Desfazer",
  unlink: "Remover link",
  unorderlist: "Lista desordenada",
  uploadErrorPlaceTxt: "Erro ao enviar",
  uploadImg: "Enviar imagem,",
  uploadPlaceTxt: "Enviando...",
  uploadTimeoutPlaceTxt: "Tempo limite excedido",
  video: "Vídeo",
  width: "Largura",
};

Vue.use(YimoVueEditor, {
  config: {
    printLog: true, // disabled console.log
    lang: pt, // lang config
  }, //wagnEditor config
});

// MIXINS
Vue.mixin(dbHandler);
Vue.mixin(dialogoLocaliza);
Vue.mixin(drawerProdutos);
Vue.mixin(image);
Vue.mixin(audio);
Vue.mixin(video);
Vue.mixin(masks);
Vue.mixin(currency);
Vue.mixin(unmask);
Vue.mixin(util);
Vue.mixin(autoCompletes);
Vue.mixin(validate);
Vue.mixin(validateForm);
Vue.mixin(regexTable);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
