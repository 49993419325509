import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // Filtros rapidos
    arrayFiltrosRapidos: [],
    objFiltrosRapidos: {},
    ativarFiltros: undefined,

    // Mapa
    mapa: {},
    arrayMapas: [],
    tabMapa: -1,
    ativarMapa: undefined,
    

    // Splash Screen
    configSplashScreen: {},
    playAnimation: 5,
    tempoSplash: undefined,
    backgroundSplash: '',
    logoSplash: '',
    sloganSplash: '',
    alinhamentoSplash: '',
    corSlogan: '#f49636',
    ativarSplash: undefined,

    configSaved: false,

    // mudanças
    trackMapa: [],
    trackFiltros: null,
    trackSplash: [],
    tc000: [{
      _rTC000_IM_LOGO:  '' ,
      _rTC000_IM_SPLASH:'' ,
      _rTC000_DS_SPLASH:'' ,
      _rTC000_CD_COR   :'' ,
      _rTC000_ID_POS   :'' ,
      _rTC000_QT_SEGSPL:'' ,
      _rTC000_ID_SPLASH:'' ,
      _rTC000_ID_CONS  :'' ,
      _rTC000_ID_MAPA  :'' ,
      _rTC000_ID_FILTRO:'' ,
    }],
    tc010: [{
      _rTC010_NR_FILTRO: '',
      _rTC010_CD_SITUAC: '',
      _rTC010_DS_FILTRO: '',
      _rTC010_IM_FILTRO: '',
    }],
    tc015: [{
      _rTC015_NR_LOJA  : '',
      _rTC015_NR_MAPA  : '',
      _rTC015_CD_SITUAC: '',
      _rTC015_DS_MAPA  : '',
    }],

    primeiraConfig: false,
    // Dialogo localiza
    dialogoMulti: false,
    // Menu lateral em produtos
    drawerProdutos: false,
    // Confere o formulário
    validacaoForm: 0,
    formValido: true,
    // Adiciona novo item na tabela
    novoItem: null,
  },
  mutations: {
    vuexSet(state, { value, stateName }) {
      state[stateName] = value
    },
  },
  actions: {
    vuexSet(context, { value, stateName }) {
      context.commit('vuexSet', { value, stateName })
    },
  },
  modules: {
  },
})
