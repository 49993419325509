import Vue from "vue";
import Vuetify from "vuetify";
import corporativo from "@saastec/corporativo";
import "@saastec/corporativo/dist/corporativo.css";
import store from "@/store";
import router from "@/router";
import i18n from "@/i18n";
import { version } from "@/../package.json";

Vue.use(corporativo, {
  vuetify: Vuetify,
  store,
  router,
  i18n,
  padroes: {
    caixa: false,
    todasAsLojas: false,
  },
  loginParams: {
    loginMDO: false, //window.location.host.toString().includes("mdotech"),
    colors: {
      primary: "#f49636",
      accent: "#fff",
      background: "#f0f0f5",
    },
    images: {
      undraw: "desenho.svg",
      logo: "saastecapp.png",
      logoSaastec: "logobk.png",
      MDOFundoDivider: "/resources/images/mdo/fundo_vermelho.png",
      MDOFundoTela: "/resources/images/mdo/fundo_cinza.png",
      MDOLogoPrincipal: "/resources/images/mdo/log_principal_MDO.png",
      MDOLogoReports: "/resources/images/mdo/reports-5.png",
      slidesMDO: [
        "/resources/images/mdo/foto1.jpg",
        "/resources/images/mdo/foto2.jpg",
        "/resources/images/mdo/foto3.jpg",
        "/resources/images/mdo/foto4.jpg",
      ],
      slides: [
        {
          path: "0.png",
          pathMobile: "0.png",
          btnSaibaMaisPath: 'botao2.png',
          btnContratarPath: 'botao1.png',
          btnSaibaMaisX: '82%',
          btnSaibaMaisY: '46vh',
          btnContratarX: '82%',
          btnContratarY: '41vh',
          width: '120px',
          linkSaibaMais:
            "https://saastec.movidesk.com/kb/article/425596/perguntas-frequentes-campanha-de-whatsapp",
          linkContratar:
            "https://api.whatsapp.com/send?phone=554121707770&text=Ol%C3%A1,%20estou%20interessado%20na%20ferramenta%20SaaSZAP",
        },
        {
          path: "5.png",
          pathMobile: "5.png",
          btnSaibaMaisPath: 'bt01.png',
          btnContratarPath: 'bt02.png',
          btnSaibaMaisX: '16%',
          btnSaibaMaisY: '87vh',
          btnContratarX: '32%',
          btnContratarY: '87vh',
          width: '80px',
          linkContratar:
            "https://api.whatsapp.com/send?phone=554121707770&text=Ol%C3%A1,%20estou%20interessado%20em%20contratar%20o%20TEF",
          linkSaibaMais:
            "https://saastec.movidesk.com/kb/article/428954/faq-tef",
        },
        {
          path: "6.png",
          pathMobile: "6.png",
          btnSaibaMaisPath: 'bto01-boleto.png',
          btnContratarPath: 'bto02-boleto.png',
          btnSaibaMaisX: '82%',
          btnSaibaMaisY: '86vh',
          btnContratarX: '82%',
          btnContratarY: '91vh',
          width: '120px',
          linkContratar:
            "https://api.whatsapp.com/send?phone=554121707770&text=Ol%C3%A1,%20estou%20interessado%20em%20contratar%20o%20Boleto",
          linkSaibaMais:
            "https://saastec.movidesk.com/kb/article/430189/faq-boleto",
        },
        {
          path: "7.png",
          pathMobile: "7.png",
          btnSaibaMaisPath: 'bt01-invent.png',
          btnContratarPath: 'bt02-invent.png',
          btnSaibaMaisX: '82%',
          btnSaibaMaisY: '81vh',
          btnContratarX: '82%',
          btnContratarY: '91vh',
          width: '70px',
          linkContratar:
            "https://api.whatsapp.com/send?phone=554121707770&text=Ol%C3%A1,%20estou%20interessado%20em%20contratar%20o%20SaaSInvent",
          linkSaibaMais:
            "https://saastec.movidesk.com/kb/article/430205/perguntas-frequentes-saasinvent",
        },
      ],
    },
  },
  version,
  idbInfo: {
    dbName: "saastecapp",
    version: 1,
  },
});
