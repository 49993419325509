export default {
  methods: {
    validateEmail(email) {
      const rgx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return rgx.test(email)
    },
    validateCnpj(cnpj) {
      // Primeiro dígito verificador
      // Multiplica cada dígito do cnpj por pesos e somando-os. Exclui-se os dígitos verificadores
      let sum = (parseInt(cnpj.toString().charAt(0), 10) * 5) +
        (parseInt(cnpj.toString().charAt(1), 10) * 4) +
        (parseInt(cnpj.toString().charAt(2), 10) * 3) +
        (parseInt(cnpj.toString().charAt(3), 10) * 2) +
        (parseInt(cnpj.toString().charAt(4), 10) * 9) +
        (parseInt(cnpj.toString().charAt(5), 10) * 8) +
        (parseInt(cnpj.toString().charAt(6), 10) * 7) +
        (parseInt(cnpj.toString().charAt(7), 10) * 6) +
        (parseInt(cnpj.toString().charAt(8), 10) * 5) +
        (parseInt(cnpj.toString().charAt(9), 10) * 4) +
        (parseInt(cnpj.toString().charAt(10), 10) * 3) +
        (parseInt(cnpj.toString().charAt(11), 10) * 2)

      let mod = sum % 11
      let sub = 11 - mod
      const ver1 = sub >= 10 ? 0 : sub

      // egundo dígito verificador
      // Multiplica cada dígito do cnpj por pesos e somando-os. Exclui-se o último dígito verificadore
      sum = (parseInt(cnpj.toString().charAt(0), 10) * 6) +
        (parseInt(cnpj.toString().charAt(1), 10) * 5) +
        (parseInt(cnpj.toString().charAt(2), 10) * 4) +
        (parseInt(cnpj.toString().charAt(3), 10) * 3) +
        (parseInt(cnpj.toString().charAt(4), 10) * 2) +
        (parseInt(cnpj.toString().charAt(5), 10) * 9) +
        (parseInt(cnpj.toString().charAt(6), 10) * 8) +
        (parseInt(cnpj.toString().charAt(7), 10) * 7) +
        (parseInt(cnpj.toString().charAt(8), 10) * 6) +
        (parseInt(cnpj.toString().charAt(9), 10) * 5) +
        (parseInt(cnpj.toString().charAt(10), 10) * 4) +
        (parseInt(cnpj.toString().charAt(11), 10) * 3) +
        (parseInt(cnpj.toString().charAt(12), 10) * 2)

      mod = sum % 11
      sub = 11 - mod
      const ver2 = sub >= 10 ? 0 : sub

      return parseInt(cnpj.toString().charAt(12), 10) === ver1 && parseInt(cnpj.toString().charAt(13), 10) === ver2
    },
    validateCpf(cpf) {
      const regexVerificaIgual = /^(.)\1+$/
      // Primeiro dígito verificador
      // Multiplica cada dígito do cpf por pesos e somando-os. Exclui-se os dígitos verificadores
      let sum = (parseInt(cpf.toString().charAt(0), 10) * 10) +
        (parseInt(cpf.toString().charAt(1), 10) * 9) +
        (parseInt(cpf.toString().charAt(2), 10) * 8) +
        (parseInt(cpf.toString().charAt(3), 10) * 7) +
        (parseInt(cpf.toString().charAt(4), 10) * 6) +
        (parseInt(cpf.toString().charAt(5), 10) * 5) +
        (parseInt(cpf.toString().charAt(6), 10) * 4) +
        (parseInt(cpf.toString().charAt(7), 10) * 3) +
        (parseInt(cpf.toString().charAt(8), 10) * 2)

      let mod = sum % 11
      let sub = 11 - mod
      const ver1 = sub >= 10 ? 0 : sub

      // Segundo dígito verificador
      // Multiplica cada dígito do cpf por pesos e somando-os. Exclui-se o último dígito verificador
      sum = (parseInt(cpf.toString().charAt(0), 10) * 11) +
        (parseInt(cpf.toString().charAt(1), 10) * 10) +
        (parseInt(cpf.toString().charAt(2), 10) * 9) +
        (parseInt(cpf.toString().charAt(3), 10) * 8) +
        (parseInt(cpf.toString().charAt(4), 10) * 7) +
        (parseInt(cpf.toString().charAt(5), 10) * 6) +
        (parseInt(cpf.toString().charAt(6), 10) * 5) +
        (parseInt(cpf.toString().charAt(7), 10) * 4) +
        (parseInt(cpf.toString().charAt(8), 10) * 3) +
        ver1 * 2
      
      mod = sum % 11
      sub = 11 - mod
      const ver2 = sub >= 10 ? 0 : sub

      return parseInt(cpf.toString().charAt(9), 10) === ver1 && parseInt(cpf.toString().charAt(10), 10) === ver2 && !regexVerificaIgual.test(cpf.toString())
    },
  },
}
