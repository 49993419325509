<template>
  <div>
    <v-card-title>
      <v-icon> information </v-icon>
      <span style="margin-left: -150px"> Manutenção de código de barras </span>
    </v-card-title>
    <v-form
      ref="form"
      v-model="preenchido"
      lazy-validation
    >
      <v-row style="margin: 0px; padding: 0px 25px">
        <v-col cols="12">
          <v-text-field
            v-model="nomeProduto"
            label="Nome produto"
            outlined
            hint="Nome do produto que aparecerar no app"
            :rules="[rules.required]"
            dense
            readonly
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="cdSKU"
            label="SKU"
            outlined
            readonly
            dense
            hint="Gerado automaticamente caso não seja preenchido"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-autocomplete
            v-model="situac"
            label="Situação"
            outlined
            dense
            hint="Situação desejada do produto"
            readonly
            style="height: 35px"
            :items="['Ativo', 'Bloqueado']"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
        >
          <v-combobox
            v-model="codBarras"
            label="Cód. Barras"
            clearable
            outlined
            dense
            :items="itemsCdbarras"
            @change="escolheCdBarras(codBarras)"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-row style="padding: 0 25px">
      <v-col
        cols="12"
        sm="6"
      >
        <v-card
          width="100%"
          height="200"
          flat
          style="border-radius: 30px"
          @click="geraNovoCdBarras"
        >
          <v-row class="rowCentered-prod">
            <v-col style="margin-top: 30px">
              <v-icon size="56">
                refresh
              </v-icon>
            </v-col>
            <v-col cols="12">
              <span>Gerar novo código de barras</span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-card
          width="100%"
          height="200"
          flat
          style="border-radius: 30px"
          @click="mudaSituac"
        >
          <v-row class="rowCentered-prod">
            <v-col style="margin-top: 30px">
              <v-icon size="56">
                {{ situac == "Ativo" ? "close" : "done" }}
              </v-icon>
            </v-col>
            <v-col cols="12">
              <span>{{
                situac == "Ativo"
                  ? "Desativar código de barras"
                  : "Ativar código de barras"
              }}</span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="rowCentered-prod">
      <v-btn
        style="margin-right: 10px; margin-top: 55px"
        color="primary"
        text
        :disabled="!preenchido"
        @click="validate"
      >
        Salvar
      </v-btn>
    </v-row>
  </div>
</template>

<script>
let user;
export default {
  props: {
    produto: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      preenchido: true,
      nomeProduto: "",
      cdSKU: "",
      codBarras: "",
      situac: "",
      rules: {
        required: (value) => !!value || "Esse campo precisa ser preenchido.",
      },
      codigoDB: [],
      itemsCdbarras: [],
      primeiraApertadanoBotao: 0,
    };
  },
  mounted() {
    this.mountInfos();
  },
  methods: {
    async mountInfos() {
      this.nomeProduto = this.produto.ce110_ds_prod;
      this.cdSKU = this.produto.ce110_cd_prode;

      let retorno = await this.request({
        metodo: "get",
        sp: "sp_ce360901007",
        params: { _rCD_PROD: this.produto.ce110_cd_prod },
      });
      retorno = retorno.database;

      retorno.forEach((f) => {
        this.itemsCdbarras.push(f.ce119_cd_barras);
      });

      this.codigoDB = retorno;
      this.codBarras = this.itemsCdbarras.find(
        (item) => item == this.produto.ce119_cd_barras,
      );
    },
    escolheCdBarras(codigo) {
      let index = this.codigoDB.findIndex((f) => f.ce119_cd_barras == codigo);
      this.situac =
        this.codigoDB[index].ce119_cd_situac == 1 ? "Ativo" : "Desabilitado";
    },
    async geraNovoCdBarras() {
      let retorno = await this.request({
        metodo: "get",
        sp: "SP_CE360901016",
        params: { _rCD_PROD: this.produto.ce110_cd_prod },
      });
      retorno = retorno.database[0].cd_barras.value;
      retorno = retorno.replace("(", "");
      retorno = retorno.replace(")", "");
      if (this.primeiraApertadanoBotao == 0) {
        this.itemsCdbarras.push(retorno);
        this.codBarras = retorno;
        this.itemsCdbarras.reverse();
        this.primeiraApertadanoBotao++;
        this.situac = "Ativo";
      } else {
        this.itemsCdbarras.splice(0, 1, retorno);
        this.codBarras = retorno;
        this.situac = "Ativo";
      }
    },
    async salvaProduto(param) {
      user = await this.getUser();
      let req = {
        _rCD_PARAM: param,
        _rCD_SITUAC: this.situac == "Ativo" ? 1 : 2,
        _rCD_PROD: this.produto.ce110_cd_prod,
        _rCD_BARRAS: this.codBarras,
        _rCD_OPESIS: user.base.us01_nr_usr,
      };
      try {
        await this.request({
          metodo: "get",
          sp: "sp_ce360901011",
          params: req,
        });
        this.notification().success({mensagem:"código de barras salvo com sucesso"});
        this.drawer = false;
      } catch (e) {
        this.notification().error({erro: "Erro ao salvar os codigo de barras"});
      }
    },
    mudaSituac() {
      if (this.situac == "Ativo") {
        this.situac = "Bloqueado";
      } else {
        this.situac = "Ativo";
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.primeiraApertadanoBotao > 0
          ? this.salvaProduto(1)
          : this.salvaProduto(2);
      } else {
        this.notification().error({erro: "Por favor preencha todos os campos"});
      }
    },
  },
};
</script>

<style>
.drawer_ofertas {
  height: 100px;
  padding: 15px 0px;
  width: 8rem !important;
  margin: 0px 7px;
  margin-bottom: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>