<template>
  <div>
    <ti-layout-principal
      :key="attMenu"
      :menu="menuLateral"
      :params="params"
    />
    <!-- <parametrizacao-primeiro-login /> -->
  </div>
</template>

<script>
import { version } from "@/../package.json"
import { menu } from '@/services/menu'
//import STLayout from '../STLayout'
//import topMenu from '../../components/topMenu'
import { mapState } from 'vuex'
// import ParametrizacaoPrimeiroLogin from '@/components/paremetrizacao-primeiro-login'

export default {
  //components: { 'st-layout': STLayout /* topMenu */  },
  data() {
    return {
      menu,
      menuLateral: [],
      attMenu: 0,
      params: {
        appName: 'SaaSERP2',
        colors: {
          light: {
            appBarBackground: '#f0f0f5',
            menuBackground: '#fff',
            footerBackground: '#e1e1e1',
            breadcrumbsBackground: '#e1e1e1',
            primary: '#f46434',
            usePrimaryBreadcrumbsText: true,
            usePrimaryFooterText: true,
          },
          dark: {
            appBarBackground: '#2f2f2e',
            menuBackground: '#2f2f2e',
            footerBackground: '#000',
            breadcrumbsBackground: '#2f2f2e',
            primary: '#d13034',
            usePrimaryBreadcrumbsText: true,
            usePrimaryFooterText: true,
          },
        },
        images: {
          logo: 'saastecapp.png',
          icon: 'favicon.png',
        },
      },
    }
  },
  computed: {
    ...mapState('corporativo', ['drawerSize']),
  },
  async mounted(){
    this.menuLateral = await this.menu()
    this.attMenu++
  },
}
</script>

<style>

</style>