<template>
  <v-card
    class="hover-card"
    style="padding: 10px;height: 325px !important;"
  >
    <v-img
      id="thumbs"
      :src="imageInfo.uri"
      :alt="imageInfo.name"
      min-heigth="250"
      max-heigth="250"
      height="250"
      contain
    />  
    
    <a
      target="_blank"
      :href="imageInfo.uri"
      :download="imageInfo.name"
      class="download-link"
    > 
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            size="22"
            color="info darken-1"
            v-bind="attrs"
            v-on="on"
          >
            download
          </v-icon>
        </template>
        <span>Baixar imagem</span>
      </v-tooltip>
    </a>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="!isEditing"
          x-small
          icon
          color="#ffababcf"
          v-bind="attrs"
          class="cancel-link"
          @click="$emit('remover')"
          v-on="on"
        >
          <v-icon color="red">
            close
          </v-icon>
        </v-btn>
      </template>
      <span>Remover</span>
    </v-tooltip>
            
    <v-card-text
      :style="{ padding: '16px 0' }"
    >
      <v-row>
        <v-col
          cols="12"
          style="margin-left: 5px"
        >
          <span style="font-weight: bold;">{{ imageInfo.name }}</span>
          <v-switch
            v-model="imageInfo.nr_princ"
            label="Imagem principal"
            dense
            color="orange darken-3"
            style="margin-bottom: -15px !important; margin-top: -10px !important;"
            @change="$emit('updateImagemPrincipal')"
          />
        </v-col>
      </v-row>
      <!-- </div> -->
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    imageInfo: {
      type: Object,
      default: () => {},
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.itensCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.itensCard > span {
  display: flex;
  align-items: center;
  gap: 1rem
}

.hover-card {
  transition: background-color 0.3s ease;
}
.hover-card:hover {
  // background-color: #FFF5EE;
}

.transparent-bg {
  background-color: transparent;
}

.thumbs {
  height: 250px;
}

.download-link {
  position: absolute;
    right: 30px;
    top: 250px;
}

.cancel-link {
  position: absolute;
    right: 11px;
    top: 250px;
}
</style>
