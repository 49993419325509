export default {
  methods: {
    async getAutocompletes() {
      this.grupoItems = [];
      try {
        let grupoDB = await this.request({
          metodo: "get",
          sp: "SP_CE360901001",
          params: [{}],
        });
        this.grupoItems = grupoDB.database;
        let secaoDB = await this.request({
          metodo: "get",
          sp: "SP_CE360901002",
          params: [{}],
        });
        this.secaoItems = secaoDB.database;
        let deptoDB = await this.request({
          metodo: "get",
          sp: "SP_CE360901003",
          params: [{}],
        });
        this.depItems = deptoDB.database;
        let colecDB = await this.request({
          metodo: "get",
          sp: "SP_CE360901004",
          params: [{}],
        });
        this.colecItems = colecDB.database;
      } catch (e) {
        this.notification().error({erro: e});
      }
    },
  },
};
