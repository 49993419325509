import i18n from "@/i18n";
import Vue from "vue";
const menuGeral = [
  {
    title: i18n.tc("MenuLateral.lojas"),
    active: false,
    icon: "store",
    path: "/lojas",
    cod: "V36SF0905A",
  },
  {
    title: i18n.tc("MenuLateral.colaboradores"),
    active: false,
    icon: "people",
    path: "/colaboradores",
    cod: "V36SF0904A",
  },
  {
    title: i18n.tc("MenuLateral.produtos"),
    active: false,
    icon: "inventory",
    children: [
      {
        title: "Dashboard",
        active: false,
        path: "/organizacao-produtos",
        cod: "V36SF0901A",
      },
      {
        title: i18n.tc("MenuLateral.manuProdutos"),
        active: false,
        path: "/produtos",
        cod: "V36SF0901B",
      },
      {
        title: i18n.tc("MenuLateral.cadastroProdutos"),
        active: false,
        path: "/cadastro-produtos",
        cod: "V36SF09010A",
      },
      {
        title: i18n.tc("MenuLateral.importProdutos"),
        active: false,
        path: "/importacao-produtos",
        cod: "V36SF0901C",
      },
      {
        title: i18n.tc("MenuLateral.importProdutosListagem"),
        active: false,
        path: "/importacao-produtos-listagem",
        cod: "V36SF0901D",
      },      
      /*{
        title: "Canvas",
        active: false,
        path: "/canvas-produtos",
        cod: "V36SF0901D",
      },*/
    ],
  },
  {
    title: i18n.tc("MenuLateral.clientes"),
    active: false,
    icon: "person",
    children: [
      {
        title: i18n.tc("MenuLateral.simplificado"),
        active: false,
        path: "/clientes/simplificado/",
        cod: "V36SF0903A",
      },
      {
        title: 'Cad. completo pessoa física', 
        active: false, 
        path: '/clientes/cadastro/pessoa-fisica',
        cod: "V36SF0903B",
      },
      {
        title: 'Cad. completo PJ', 
        active: false, 
        path: '/clientes/cadastro/pessoa-juridica',
        cod: "V36SF0903C",
      },
      {
        title: 'Alterar CPF/CNPJ do cliente', 
        active: false, 
        path: '/clientes/alterar',
        cod: "V36SF0903D",
      },
      {
        title: 'Importação de clientes', 
        active: false, 
        path: '/clientes/importacao',
        cod: "V36SF0908A",
      },
    ],
  },
  {
    title: "SaaSTEC",
    active: false,
    icon: "show_chart",
    children: [
      {
        title: "Dashboard",
        active: false,
        path: "/saastec-dashboard",
        cod: "V36SF0902A",
      },
    ],
  },
];

setTimeout(async () => {
  const usuario = await Vue.prototype.$wso2.getUserData();
  const mixins = new Vue();
  const retorno = await mixins.request({
    metodo: "GET",
    sp: "SP_CE360900001",
    params: {},
  });
  
  if (retorno.database.length > 0) {
    menuGeral.splice(1, 0, {
      title: i18n.tc("MenuLateral.configTelas"),
      active: false,
      icon: "toggle_on",
      path: "/config/telas",
      cod: "V36SF0907A",
    });
  }

  if (usuario.base.ce000_tp_segm === 10) {
    menuGeral.push({
      title: "Médicos",
      active: false,
      icon: "medical_information",
      path: "/medicos",
      cod: "V36SF0906A",
    });
  }
}, 1000);


const verificaMenu = async (menu, menuAcessiveis = [], isSubMenu = false) => {
  let subMenu = [];
  for (let m of menu) {
    if (m.children) {
      const mn = await verificaMenu(m.children, [], true);

      if (mn.length > 0) {
        const menuItem = { ...m, children: mn };
        if (isSubMenu) {
          subMenu.push(menuItem);
        } else {
          menuAcessiveis.push(menuItem);
        }
      }
    } else {
      let acesso = await Vue.prototype.$wso2.possuiAcesso(m.cod);
      if (acesso != 0) {
        if (isSubMenu) {
          subMenu.push(m);
        } else {
          menuAcessiveis.push(m);
        }
      }
    }
  }
  return isSubMenu ? subMenu : menuAcessiveis;
}

const menu = async () => {
  return await verificaMenu(menuGeral, [])
}



export { menu };
