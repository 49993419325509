<!--
 Copyright SaasTec Labs - 2024. Todos os direitos reservados
 -----
 Arquivo: App.vue
 Projeto: SaaSERP2
 Criação do arquivo: 07/02/2022 - 11:24:54
 Autor: SaaSTec Labs
 -----
 Última modificação: 30/05/2022 - 15:46:10
 Modificado por: pedro.rosa
-->
<template>
  <v-app :style="{ backgroundColor: '#f0f0f5' }">
    <ti-notification style="z-index: 99" />
    <!-- <splash-screen /> -->
    <router-view />
    <ti-loader />
  </v-app>
</template>
<script>
import SplashScreen from "@/components/SplashScreen";

export default {
  // components: { SplashScreen },
  async mounted() {},
};
</script>