<template>
  <div>
    <link
      href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp"
      rel="stylesheet"
    >
    <v-card-title>
      <v-icon> info </v-icon>
      <span style="margin-left: 30px"> Cadastro de ofertas </span>
    </v-card-title>

    <v-card-title> Filtros </v-card-title>
    <v-slide-group
      v-model="filtroEscolhido"
      class="pa-4"
      center-active
      show-arrows
      mandatory
    >
      <v-slide-item
        v-for="(filtro, i) in filtrosRapidos"
        :key="`filtro-${i}`"
        v-slot="{ toggle, active }"
      >
        <v-card
          width="100%"
          height="120"
          :style="{
            padding: '15px 0',
            borderBottom: '5px solid #f46434 !important',
          }"
          class="drawer_ofertas"
          @click="toggle"
        >
          <v-row class="rowCentered">
            <i
              class="material-icons"
              :style="{
                color: `${active ? '#f46434' : '#666'} !important`,
                fontSize: '48px',
              }"
            >
              {{ filtro.tc010_im_filtro }}
            </i>
          </v-row>
          <v-row class="rowCentered">
            <span class="ofertas">{{ filtro.tc010_ds_filtro }}</span>
          </v-row>
        </v-card>
      </v-slide-item>
    </v-slide-group>
    <v-container>
      <v-form
        ref="form"
        v-model="formOferta"
        lazy-validation
      >
        <v-row no-gutters>
          <v-col cols="12">
            <v-autocomplete
              v-model="cdBarras"
              label="Cód. barras"
              outlined
              dense
              :rules="regraEscolherCdBarras"
              hint="Dica: para encontrar o cód. barras mais rápido, use um scanner"
              :items="arrayCdBarras"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              :key="keyDesc"
              v-model="PcDesconto"
              v-money="porcentagem"
              label="Desconto"
              type="tel"
              outlined
              :prepend-inner-icon="
                porcentagemBoolean ? 'attach_money' : 'percent'
              "
              append-icon="import_export"
              dense
              @click:append="changeValue"
            />
            <v-divider />
            <v-card-title> Término do desconto </v-card-title>
            <v-card-subtitle>
              O desconto será aplicado assim que salvas as configurações
            </v-card-subtitle>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <v-menu
                  ref="menu"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :close-on-content-click="dataSelecionada"
                >
                  <template #activator="{ on, attrs }">
                    <v-combobox
                      v-model="dataFinalFormatada"
                      label="Data"
                      outlined
                      dense
                      readonly
                      :rules="regraEscolherData"
                      v-bind="attrs"
                      v-on="on"
                      @click="dataSelecionada = false"
                    />
                  </template>
                  <v-date-picker
                    v-model="dataFinal"
                    scrollable
                    :min="dataInicial"
                    @click:date="dataSelecionada = true"
                  />
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu
                  ref="menu"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :close-on-content-click="minutoSelecionado"
                >
                  <template #activator="{ on, attrs }">
                    <v-combobox
                      v-model="horaFinal"
                      label="Horário"
                      outlined
                      dense
                      readonly
                      :rules="regraEscolherHorario"
                      v-bind="attrs"
                      v-on="on"
                      @click="minutoSelecionado = false"
                    />
                  </template>
                  <v-time-picker
                    v-model="horaFinal"
                    format="24hr"
                    scrollable
                    @click:minute="minutoSelecionado = true"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
      <v-row class="rowCentered-prod">
        <v-btn
          style="margin-right: 10px"
          color="primary"
          text
          :disabled="!formOferta"
          @click="salvaDesconto"
        >
          Salvar
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
var usuario;
export default {
  props: {
    produto: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      /* Form */
      formOferta: true,
      /* Fim form */

      /* Horário */
      minutoSelecionado: false,
      regraEscolherHorario: [(h) => !!h || "Escolha o horário"],
      horaFinal: "",
      /* Fim horário */

      /* Data */
      dataSelecionada: false,
      regraEscolherData: [(d) => !!d || "Escolha a data"],
      dataFinal: "",
      dataFinalFormatada: "",
      dataInicial: new Date().toISOString().substr(0, 10),
      /* Fim data */

      /* porcentagem de desconto e cód. barras */
      PcDesconto: 0,
      keyDesc: 0,
      filtrosRapidos: [],
      filtroEscolhido: 0,
      arrayCdBarras: [],
      regraEscolherCdBarras: [(c) => !!c || "Escolha um código de barras"],
      cdBarras: 0,
      porcentagem: {
        decimal: ",",
        thousands: ".",
        prefix: "R$",
        suffix: "",
        precision: 2,
      },
      porcentagemBoolean: true,
      /* Fim % de desconto e cód. barras */
    };
  },
  watch: {
    porcentagemBoolean() {
      if (this.porcentagemBoolean) {
        this.porcentagem.prefix = "R$";
        this.porcentagem.suffix = "";
      } else {
        this.porcentagem.prefix = "";
        this.porcentagem.suffix = "%";
      }
    },
    dataFinal: {
      handler() {
        this.dataFinalFormatada = this.formataData(this.dataFinal);
      },
    },
    cdBarras: {
      async handler() {
        var dataHoje = new Date()
          .toISOString()
          .substr(0, 16)
          .replaceAll("-", "")
          .replace("T", "")
          .replace(":", "");
        const req = {
          _rTC011_CD_BARRAS: this.cdBarras,
          _rTC011_DH_FIM: parseInt(dataHoje, 10),
        };

        var retorno = await this.request({
          metodo: "get",
          sp: "SP_CE360901010",
          params: req,
        });
        retorno = retorno.database;
        if (retorno.length > 0) {
          // Encontra indice do filtro
          let i = this.filtrosRapidos.findIndex(
            (i) => i.tc010_nr_filtro === retorno.tc011_nr_filtro,
          );
          // Atribui o indice ao filtro
          this.filtroEscolhido = i;
          // Percentual desconto
          this.PcDesconto = this.pcDBtoString(retorno[0].tc011_pc_descp);
          this.keyDesc++;
          // Data final com o método de transformação do valor numérico do banco para string ISO
          this.dataFinal = this.dataDBtoISO(retorno[0].tc011_dh_fim);
          // Hora final
          this.horaFinal = this.hourDBtoISO(retorno[0].tc011_dh_fim);
        }
      },
    },
  },
  async mounted() {
    this.montaCdBarras();
    usuario = await this.getUser();
    await this.carregaFiltros();
  },
  methods: {
    changeValue() {
      this.porcentagemBoolean = !this.porcentagemBoolean;
    },
    async montaCdBarras() {
      let retorno = await this.request({
        metodo: "get",
        sp: "SP_CE360901017",
        params: { _rCD_PROD: this.produto.ce110_cd_prod },
      });
      retorno = retorno.database;
      retorno.forEach((f) => {
        this.arrayCdBarras.push({
          text: f.ce119_cd_barras.toString(),
          value: f.ce119_cd_barras,
        });
      });
    },
    formataData(data) {
      if (!data) return null;

      const [ano, mes, dia] = data.split("-");

      return `${dia}/${mes}/${ano}`;
    },

    dataDBtoISO(data) {
      if (!data) return null;

      data = data.toString().substr(0, 8);

      const ano = data.substr(0, 4);
      const mes = data.substr(4, 2);
      const dia = data.substr(6, 2);

      return `${ano}-${mes}-${dia}`;
    },
    hourDBtoISO(hour) {
      if (!hour) return null;

      hour = hour.toString().substr(8, 4);

      const hora = hour.substr(0, 2);
      const minuto = hour.substr(2, 2);

      return `${hora}:${minuto}`;
    },
    pcDBtoString(pc) {
      if (!pc) return null;

      pc = pc.toString();

      if (pc.includes(".")) {
        pc = pc.replace(".", ",") + "%";
      } else {
        pc = pc + ",00%";
      }
      return pc;
    },
    async carregaFiltros() {
      try {
        let retorno = await this.request({
          metodo: "get",
          sp: "SP_CE360900009",
          params: {},
        });
        if (retorno.database.length > 0) {
          this.filtrosRapidos = retorno.database.map((r) => {
            return {
              ...r,
              tc010_im_filtro: atob(r.tc010_im_filtro),
            };
          });
        }
      } catch {
        this.notification().error({erro: "Não foi possível carregar os filtros"});
      }
    },
    async salvaDesconto() {
      let valor = 0;
      let precoMercadoria = 0;
      if (this.porcentagemBoolean) {
        precoMercadoria = this.produto.ce110_vl_venda
          .replace("R$", "")
          .replace(",", ".");
        valor =
          (parseFloat(this.PcDesconto.replace("R$", "").replace(",", ".")) /
            parseFloat(precoMercadoria)) *
          100;
      }
      if (this.$refs.form.validate()) {
        try {
          let i = this.filtroEscolhido;
          let periodoDesc = parseFloat(
            `${this.dataFinal.replaceAll("-", "")}${this.horaFinal.replace(
              ":",
              "",
            )}`,
          );
          let pcDesc = parseFloat(
            this.porcentagemBoolean
              ? valor
              : this.PcDesconto.replace("%", "").replace(",", "."),
          );
          const req = {
            _rTC011_NR_FILTRO: this.filtrosRapidos[i].tc010_nr_filtro,
            _rTC011_CD_BARRAS: this.cdBarras,
            _rTC011_DH_FIM: periodoDesc,
            _rTC011_CD_OPESIS: usuario.base.us01_nr_usr,
            _rTC011_PC_DESCP: pcDesc,
          };
          await this.request({
            metodo: "get",
            sp: "SP_CE360901009",
            params: req,
          });
          this.notification().success({mensagem:"Oferta salva com sucesso!"});
        } catch {
          this.notification().error({erro: "Erro ao se comunicar com o servidor."});
        }
      }
    },
  },
};
</script>

<style>
.drawer_ofertas {
  height: 100px;
  padding: 15px 0px;
  width: 8rem !important;
  margin: 0px 7px;
  margin-bottom: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>