<template>
  <div>
    <dialogoncm
      v-model="dialogoNCM"
      :sp="sp"
      :campos="opcoesTipo"
      :nome="nome"
      :filtro="true"
      :adicionar="false"
      @returnValue="getNCM"
    />
    <v-row>
      <v-col
        cols="12"
        sm="4"
        class="colClass1"
      >
        <v-card
          v-if="carregando"
          elevation="0"
          color="transparent"
          class="cardCenteredLoading"
        >
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          />
        </v-card>
        <v-card
          v-else
          elevation="0"
          color="transparent"
          style="padding: 20px"
          :disabled="novo || editar"
        >
          <v-autocomplete
            v-model="situacaoGps"
            label="Situação"
            outlined
            dense
            :items="['Ativo', 'Todos']"
            @change="montaObj"
          />
          <v-btn
            outlined
            width="100%"
            @click="novoGrupoPai"
          >
            <v-icon>add</v-icon>
            Novo grupo
          </v-btn>
          <v-treeview
            v-model="tree"
            :items="items"
            activatable
            item-key="ce113_cd_grp"
            item-text="ce113_ds_grp"
          >
            <template #label="{ item }">
              <div
                class="treeViewLabelDiv"
                @click="getInfo(item)"
              >
                <span>{{ item.ce113_ds_grp }}</span>
              </div>
            </template>
            <template #prepend="{ item, open }">
              <v-icon v-if="item.children.length > 0">
                {{ open ? "folder_open" : "folder" }}
              </v-icon>
              <v-icon v-else>
                description
              </v-icon>
            </template>
          </v-treeview>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="8"
        class="colClass2"
      >
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-card
            elevation="0"
            :disabled="!novo && !editar"
          >
            <v-row style="margin-top: 10px">
              <v-col
                cols="8"
                sm="10"
              >
                <v-text-field
                  v-model="hier"
                  label="Hierarquia"
                  outlined
                  dense
                  hint="Hierarquia do grupo, apenas visualização"
                  readonly
                />
              </v-col>
              <v-col
                cols="4"
                sm="2"
              >
                <v-text-field
                  v-model="grpsup"
                  label="Grupo superior"
                  outlined
                  dense
                  hint="Grupo superior"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="desc"
                  label="Descrição"
                  outlined
                  dense
                  hint="Nome do grupo"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="situac"
                  label="Situação"
                  outlined
                  dense
                  hint="Situação do grupo"
                  :items="['Ativo', 'Desabilitado']"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="visiVenda"
                  label="Visivel para venda"
                  outlined
                  dense
                  :items="['Sim', 'Não']"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="8"
                sm="10"
              >
                <v-text-field
                  v-model="ncm"
                  label="NCM"
                  outlined
                  dense
                  hint="Código NCM"
                  @click="reqNCM"
                />
              </v-col>
              <v-col
                cols="4"
                sm="2"
              >
                <v-text-field
                  v-model="execao"
                  label="Exeção"
                  outlined
                  dense
                  hint="Exeção para o NCM"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="icms"
                  label="ICMS"
                  outlined
                  dense
                  hint="Código ICMS"
                  :items="retornaICMS"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="un"
                  label="Unidade"
                  outlined
                  dense
                  hint="Unidade do produto"
                  :items="retornaUn"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="dep"
                  label="Departamento"
                  outlined
                  dense
                  hint="Departamento do grupo"
                  :items="retornaNomesDep"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="secao"
                  label="Seção"
                  outlined
                  dense
                  hint="Seção do grupo"
                  :items="retornaNomesSecao"
                />
              </v-col>
            </v-row>
          </v-card>
          <div class="buttonDiv">
            <v-btn
              color="primary"
              text
              :disabled="novo || editar"
              @click="novoGrupo"
            >
              Novo
            </v-btn>
            <v-btn
              color="primary"
              text
              :disabled="novo || editar"
              @click="editarGrupo"
            >
              Editar
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="salvarGrupo"
            >
              Salvar
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="cancelar"
            >
              Cancelar
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dialogoLocalizancm from "../../dialogoLocaliza/";
export default {
  components: { dialogoncm: dialogoLocalizancm },
  data: () => {
    return {
      items: [],
      tree: [],
      sp: "",
      opcoesTipo: [],
      nome: "",

      //Inputs
      situacaoGps: "Ativo",
      valid: true,
      hier: null,
      grpsup: null,
      desc: null,
      situac: null,
      visiVenda: null,
      ncm: null,
      execao: null,
      icms: null,
      un: null,
      dep: null,
      secao: null,
      rules: {
        required: (value) => !!value || "Esse campo precisa ser preenchido.",
      },
      // items v-autocompletes
      secaoItems: [],
      depItems: [],
      ICMSItems: [],
      unItems: [],

      nivel: 0,
      dialogoNCM: false,

      //identificadores
      cdGrpSelecionado: 0,
      carregando: true,

      //verificadores
      novo: false,
      editar: false,
      key: 0,
    };
  },
  computed: {
    retornaNomesSecao() {
      return this.secaoItems.map(
        (secao) => `${secao.ce176_cd_secao}-${secao.ce176_ds_secao}`,
      );
    },
    retornaNomesDep() {
      return this.depItems.map(
        (dep) => `${dep.ce175_cd_depto}-${dep.ce175_ds_depto}`,
      );
    },
    retornaICMS() {
      return this.ICMSItems.map(
        (icms) => `${icms.nf100_cd_class}-${icms.nf100_ds_class}`,
      );
    },
    retornaUn() {
      return this.unItems.map(
        (un) => `${un.nf722_cd_uncom}-${un.nf722_ds_uncom}`,
      );
    },
  },
  mounted() {
    this.montaObj();
  },
  methods: {
    reqNCM() {
      this.sp = "SP_CE360903010";
      (this.opcoesTipo = [
        {
          value: "NF730_CD_NPS",
          text: "Cd. NCM",
          type: ["text"],
        },
        {
          value: "NF730_DS_NPS",
          text: "Ds. NCM",
          type: ["text"],
        },
      ]),
      (this.nome = "NCM");
      this.dialogoNCM = true;
    },
    getNCM(e) {
      this.ncm = e.nf730_cd_nps;
      this.execao = e.nf730_cd_exncm;
    },
    novoGrupoPai() {
      this.novo = true;
      this.grpsup = null;
      this.nivel = 0;
      this.desc = null;
      this.visiVenda = null;
      this.ncm = null;
      this.execao = null;
      this.icms = null;
      this.un = null;
      this.dep = null;
      this.secao = null;
      this.cdGrpSelecionado = null;
      this.hier = null;
    },
    novoGrupo() {
      if (this.cdGrpSelecionado == 0) {
        this.novoGrupoPai();
      }
      this.novo = true;
      this.grpsup = this.cdGrpSelecionado;
      this.nivel = this.nivel + 1;
      this.desc = null;
      this.visiVenda = null;
      this.ncm = null;
      this.execao = null;
      this.icms = null;
      this.un = null;
      this.dep = null;
      this.secao = null;
      this.cdGrpSelecionado = null;
    },
    editarGrupo() {
      this.editar = true;
    },
    async salvarGrupo() {
      let usuario = await this.getUser();
      if (this.$refs.form.validate()) {
        let ent = {
          _rNR_NOVO: this.novo ? 1 : 2,
          _rCD_GRP: this.cdGrpSelecionado,
          _rDS_HIER: this.novo
            ? this.hier !== null
              ? `${this.hier}>${this.desc}`
              : this.desc
            : this.hier,
          _rNR_NIVEL: this.nivel,
          _rCD_GRPSUP: this.grpsup,
          _rDS_GRP: this.desc,
          _rCD_SITUAC: this.situac == "Ativo" ? 1 : 2,
          _rID_EXIBLJ: this.visiVenda == "Sim" ? 1 : 2,
          _rCD_NCM: this.ncm,
          _rCD_EXNCM: this.execao,
          _rCD_CLASS:
            this.icms != null ? parseInt(this.icms.split("-")[0]) : null,
          _rCD_UNCOM: this.un != null ? this.un.split("-")[0] : null,
          _rCD_DEPTO:
            this.dep != null ? parseInt(this.dep.split("-")[0]) : null,
          _rCD_SECAO:
            this.secao != null ? parseInt(this.secao.split("-")[0]) : null,
          _rCD_OPESIS: usuario.base.us01_nr_usr,
        };
        try {
          await this.request({
            metodo: "get",
            sp: "SP_CE360901018",
            params: ent,
          });
          this.novo = false;
          this.editar = false;
          this.notification().success({ mensagem: "Informações salvas com sucesso" });
        } catch (error) {
          this.notification().error({erro: "Não foi possivel salvar as informações"});
        } finally {
          this.montaObj();
        }
      }
    },
    cancelar() {
      this.novo = false;
      this.editar = false;
    },
    getAutocompletes(item) {
      let index = this.retornaICMS.findIndex(
        (f) => f.split("-")[0] == item.ce113_cd_class,
      );
      if (index !== -1) {
        this.icms = this.retornaICMS[index];
      } else {
        this.icms = null;
      }
      index = this.retornaNomesSecao.findIndex(
        (f) => f.split("-")[0] == item.ce113_cd_secao,
      );
      if (index !== -1) {
        this.secao = this.retornaNomesSecao[index];
      } else {
        this.secao = null;
      }
      index = this.retornaNomesDep.findIndex(
        (f) => f.split("-")[0] == item.ce113_cd_depto,
      );
      if (index !== -1) {
        this.dep = this.retornaNomesDep[index];
      } else {
        this.dep = null;
      }
      index = this.retornaUn.findIndex(
        (f) => f.split("-")[0] == item.ce113_cd_uncom,
      );
      if (index !== -1) {
        this.un = this.retornaUn[index];
      } else {
        this.un = null;
      }
    },
    getInfo(item) {
      this.hier = item.ce113_ds_grpex;
      this.grpsup = item.ce113_nr_grpsup;
      this.desc = item.ce113_ds_grp;
      this.situac = item.ce113_cd_situac == 1 ? "Ativo" : "Desabilitado";
      this.visiVenda = item.ce113_id_exiblj == 1 ? "Sim" : "Não";
      this.ncm = item.ce113_cd_nps;
      this.execao = item.ce113_cd_exncm;
      this.getAutocompletes(item);
      this.cdGrpSelecionado = item.ce113_cd_grp;
      this.nivel = item.ce113_nr_nivel;
    },
    async montaObj() {
      let grupos = [];
      try {
        this.carregando = true;
        grupos = await this.request({
          metodo: "get",
          sp: "SP_CE360901001",
          params: { _rSITUAC: this.situacaoGps == "Ativo" ? 1 : 2 },
        });
        this.items = grupos.database;
        this.items = this.recursive(this.items);
        let secaoDB = await this.request({
          metodo: "get",
          sp: "SP_CE360901002",
          params: {},
        });
        this.secaoItems = secaoDB.database;
        let deptoDB = await this.request({
          metodo: "get",
          sp: "SP_CE360901003",
          params: {},
        });
        this.depItems = deptoDB.database;
        let icmsDB = await this.request({
          metodo: "get",
          sp: "SP_CE360901020",
          params: {},
        });
        this.ICMSItems = icmsDB.database;
        let unDB = await this.request({
          metodo: "get",
          sp: "SP_CE360901021",
          params: {},
        });
        this.unItems = unDB.database;
      } catch (err) {
        this.notification().error({erro: "Erro ao trazer as informações"});
      } finally {
        this.carregando = false;
      }
    },
    recursive(data, parent = null) {
      return data.reduce((r, e) => {
        if (e.ce113_nr_grpsup == parent) {
          e.children = this.recursive(data, e.ce113_cd_grp);
          r.push(e);
        }
        return r;
      }, []);
    },
  },
};
</script>

<style>
.treeViewLabelDiv {
  height: 35px;
  display: flex;
  align-items: center;
}
.buttonDiv {
  width: 100%;
  display: flex;
  justify-content: center;
}
.colClass1 {
  max-height: 625px;
  overflow-y: overlay;
  border-radius: 20px 0px 0px 20px;
  background: #f2f2f2;
}
.colClass2 {
  background: #fff;
  border-radius: 0px 20px 20px 0px;
}
.cardCenteredLoading {
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
</style>