<template>
  <div>
    <dialogo-localiza
      v-model="dialogoCadastroProduto"
      :sp="sp"
      :campos="opcoesTipo"
      :nome="nome"
      :filtro="usarFiltro"
      :adicionar="adicionar"
      @returnValue="returnMultifilterValue"
    />
    <v-card elevation="0">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-container>
          <v-row>
            <v-col
              v-for="(campo, i) in campos"
              :key="`campo-${i}`"
              cols="12"
            >
              <v-card-title> {{ campo.DIVISION }} </v-card-title>
              <v-row>
                <v-col
                  v-for="(field, j) in campo.fields"
                  :key="`field-${j}`"
                  :cols="12"
                  :md="field.cols"
                >
                  <v-autocomplete
                    v-if="field.vuetify === 'autocomplete'"
                    :id="field.id"
                    v-model="field.model"
                    outlined
                    dense
                    :prepend-inner-icon="field.required ? '*' : ''"
                    :label="field.nome"
                    :items="field.items"
                    :placeholder="field.placeholder"
                    :required="field.required"
                    :loading="field.loading"
                    :disabled="field.disabled"
                  />
                  <v-switch
                    v-else-if="field.vuetify === 'switch'"
                    :id="field.id"
                    v-model="field.model"
                    justify-center
                    inset
                    :label="
                      field.items.find((item) => item.value === field.model)
                        .text
                    "
                    :required="field.required"
                    :disabled="field.disabled"
                  />
                  <v-text-field
                    v-else-if="field.vuetify === 'textfield'"
                    :id="field.id"
                    v-model="field.model"
                    v-mask="mask(field.model, field.mask)"
                    v-money="field.money ? field.money : undefined"
                    outlined
                    dense
                    class="my-input"
                    :prepend-inner-icon="field.required ? '*' : ''"
                    :readonly="field.readonly ? field.readonly : false"
                    :type="field.type"
                    :label="field.nome"
                    :placeholder="field.placeholder ? field.placeholder : ''"
                    :rules="field.rules ? field.rules : []"
                    :persistent-hint="field.hint ? true : false"
                    :hint="field.hint ? field.hint : ''"
                    :counter="field.contaCaracter"
                    :required="field.required"
                    :loading="field.loading"
                    :maxlength="field.maxLength"
                    :disabled="field.disabled"
                    @keyup="uppercaseTextField(j)"
                  />
                  <v-text-field
                    v-else-if="field.vuetify === 'searchtype'"
                    :id="field.id"
                    v-model="field.model"
                    v-mask="mask(field.model, field.mask)"
                    outlined
                    dense
                    :prepend-inner-icon="field.required ? '*' : ''"
                    :type="field.type"
                    :label="field.nome"
                    readonly
                    :placeholder="field.placeholder ? field.placeholder : ''"
                    :rules="field.rules ? field.rules : []"
                    :persistent-hint="field.hint ? true : false"
                    :hint="field.hint ? field.hint : ''"
                    :counter="field.counter ? field.counter : null"
                    :required="field.required"
                    :loading="field.loading"
                    :maxlength="field.maxLength"
                    :disabled="field.disabled"
                    @click="abreDialogoLocaliza(field)"
                  />
                  <v-combobox
                    v-else-if="field.vuetify === 'combobox'"
                    :id="field.id"
                    v-model="field.model"
                    outlined
                    dense
                    :prepend-inner-icon="field.required ? '*' : ''"
                    :items="field.items"
                    :label="field.nome"
                    :placeholder="field.placeholder"
                    :required="field.required"
                    :loading="field.loading"
                    :disabled="field.disabled"
                  />
                  <v-menu
                    v-else-if="field.vuetify === 'datepicker'"
                    v-model="field.active"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-combobox
                        :id="field.id"
                        v-model="field.model"
                        chips
                        outlined
                        dense
                        small-chips
                        :label="field.nome"
                        :placeholder="field.placeholder"
                        :required="field.required"
                        :loading="field.loading"
                        :disabled="field.disabled"
                        prepend-inner-icon="date_range"
                        readonly
                        deletable-chips
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="dataEscolhida"
                      scrollable
                      :max="field.max"
                      :min="field.min"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="field.active = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="formatData(field, dataEscolhida)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <v-checkbox
                    v-else-if="field.vuetify === 'checkbox'"
                    v-model="field.model"
                    :style="{ marginTop: '3px' }"
                    :label="field.nome"
                    :disabled="field.disabled"
                    :prepend-inner-icon="field.required ? '*' : ''"
                  />
                  <div
                    v-else-if="field.vuetify === 'text'"
                    :style="`${
                      $vuetify.theme.dark
                        ? 'background-color: #333; color: white'
                        : ''
                    }`"
                  >
                    <v-card-subtitle>
                      {{ field.nome }}
                    </v-card-subtitle>
                    <yimo-vue-editor
                      v-model="field.model"
                      :style="`${
                        $vuetify.theme.dark
                          ? 'background-color: #555; color: white'
                          : ''
                      }`"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>


<script>
import { mapState, mapActions } from "vuex";
import dialogoLocaliza from "@/components/dialogoLocaliza";
export default {
  components: { dialogoLocaliza },
  computed: {
    ...mapState(["validacaoForm"]),
  },
  watch: {
    validacaoForm: {
      deep: true,
      handler() {
        this.vuexSet({
          value: this.$refs.form.validate(),
          stateName: "formValido",
        });
      },
    },
  },
  props: {
    campos: {
      type: Array,
      required: true,
      default: () => {
        return [
          {
            DIVISION: "DIVISÃO 1",
            fields: [
              {
                model: "Texto de exemplo",
                vuetify: "textfield",
                type: "text", // principais: text, tel, password
                nome: "Exemplo campo de texto",
                placeholder: "Sem opções de escolha, você escreve o que quiser", // opcional
                cols: 4,
                rules: [
                  (r) => r.length <= 10 || "Não deve passar de 10 caracteres",
                ], // opcional
                counter: 10, // opcional
                required: true,
              },
              {
                model: "",
                vuetify: "datepicker",
                active: false,
                nome: "Seletor de data",
                cols: 3,
                icon: "calendar", // opcional
                required: false,
              },
              {
                model: "item 1",
                vuetify: "autocomplete",
                items: ["item 1", "item 2", "item 3"],
                nome: "Exemplo campo autocomplete",
                placeholder:
                  'Pode somente ser preenchido com o que está em "itens"', // opcional
                cols: 4,
                required: false,
              },
              {
                model: "item 1 e item 2",
                vuetify: "combobox",
                items: ["item 1", "item 2", "item 3"],
                nome: "Exemplo campo combobox",
                placeholder:
                  "Você pode completar o campo com o teclado ou utilizar algum item fornecido", // opcional
                cols: 4,
                required: false,
              },
              {
                vuetify: "textfield",
                type: "text", // principais: text, tel, password
                nome: "Exemplo campo de texto",
                placeholder: "Sem opções de escolha, você escreve o que quiser", // opcional
                cols: 4,
                rules: [
                  (r) => r.length <= 10 || "Não deve passar de 10 caracteres",
                ], // opcional
                counter: 10, // opcional
                required: true,
              },
              {
                vuetify: "autocomplete",
                items: ["item 1", "item 2", "item 3"],
                nome: "Exemplo campo autocomplete",
                placeholder:
                  'Pode somente ser preenchido com o que está em "itens"', // opcional
                cols: 4,
                required: false,
              },
              {
                vuetify: "combobox",
                items: ["item 1", "item 2", "item 3"],
                nome: "Exemplo campo combobox",
                placeholder:
                  "Você pode completar o campo com o teclado ou utilizar algum item fornecido", // opcional
                cols: 4,
                required: false,
              },
              {
                model:
                  "Caixa de redação. Aqui você pode adicionar <b>efeitos de escrita</b>, colocar links, entre outros.",
                vuetify: "text",
                nome: "Caixa de texto",
                cols: 12,
                required: false,
              },
            ],
          },
          {
            DIVISION: "DIVISÃO 2",
            fields: [
              {
                vuetify: "textfield",
                type: "text", // principais: text, tel, password
                nome: "Exemplo campo de texto",
                placeholder: "Sem opções de escolha, você escreve o que quiser", // opcional
                cols: 4,
                rules: [
                  (r) => r.length <= 10 || "Não deve passar de 10 caracteres",
                ], // opcional
                counter: 10, // opcional
                required: true,
              },
              {
                vuetify: "autocomplete",
                items: ["item 1", "item 2", "item 3"],
                nome: "Exemplo campo autocomplete",
                placeholder:
                  'Pode somente ser preenchido com o que está em "itens"', // opcional
                cols: 4,
                required: false,
              },
              {
                vuetify: "combobox",
                items: ["item 1", "item 2", "item 3"],
                nome: "Exemplo campo combobox",
                placeholder:
                  "Você pode completar o campo com o teclado ou utilizar algum item fornecido", // opcional
                cols: 4,
                required: false,
              },
            ],
          },
        ];
      },
    },
  },
  data() {
    return {
      outraCoisa: ["###.###.###-##", "##.###.###/0001-##"],
      dataEscolhida: new Date().toISOString().substr(0, 10),
      valid: true,
      usarFiltro: false,
      sp: "",
      opcoesTipo: [],
      nome: "",
      adicionar: false,
      dialogoCadastroProduto: false,
    };
  },
  methods: {
    ...mapActions(["vuexSet"]),
    uppercaseTextField(index) {
      this.campos[0].fields[index].model =
        this.campos[0].fields[index].model.toUpperCase();
    },
    mask(input, mask) {
      if (mask && input) {
        if (typeof mask === "string") {
          return mask;
        } else {
          let maskIndex = 0;
          let resposta;
          while (maskIndex < mask.length) {
            if (
              input.replace(/[^\w\s]/gi, "").length <=
              (mask[maskIndex].match(/#/g) || []).length
            ) {
              resposta = mask[maskIndex];
              maskIndex = mask.length;
            } else {
              maskIndex++;
            }
          }
          return resposta;
        }
      }
    },
    formatData(field, data) {
      const [ano, mes, dia] = data.split("-");
      field.model = `${dia}/${mes}/${ano}`;
      field.active = false;
    },
    abreDialogoLocaliza(obj) {
      this.usarFiltro = obj.filtro;
      this.sp = obj.sp;
      this.opcoesTipo = obj.opcoesTipo;
      this.nome = obj.nome;
      this.adicionar = obj.adicionar;
      this.dialogoCadastroProduto = true;
    },
    returnMultifilterValue(valor) {
      const vm = this;
      this.campos.forEach((secao) => {
        let obj = secao.fields.find((f) => f.nome === vm.nome);
        obj.retorno = valor;
      });
    },
  },
};
</script>

<style>
.my-input input {
  text-transform: uppercase;
}
</style>
