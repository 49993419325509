/*
  Copyright © 2024 SaaSTec Labs. Todos os direitos reservados
*/
import * as wjcCore from "@grapecity/wijmo";

/*
  Seta a licença anual do Wijmo Enterprise adquirida pela SaaSTec para todo o projeto

  A licença foi configurada para os hostnames abaixo, porém pode ser gerada na nossa
  licença uma nova chave para até 100 hostnames. Essa chave é gerada na conta da SaaSTec
  junto a Mercius

*/
wjcCore.setLicenseKey(
  "mdotechreportshomol.saastecerp.com.br|hubhomol.saastecerp.com.br|reportshomol.saastecerp.com.br|mdotechreports.saastecerp.com.br|reports.saastecerp.com.br|admin.saastecerp.com.br|localhost|com.saastecerp.com.br|comhomol.saastecerp.com.br|hub.saastecerp.com.br|adminhomol.saastecerp.com.br|erp2.saastecerp.com.br|erp2homol.saastecerp.com.br,556462332988549#B0Vbwh6c4J7bwVmcsInYu46bj9CcyV6YlR7chF6cuw6bt3GaiVHasInYu46bj9CcyV6YlR7chF6cuw6bt3GazRncvBXZyh6YlR7bk5mI0IyctRkIsISYpd6bs3mbjVGVgMWZUNVYhNlI0ISYONkIsISO4UDO8kjMzMjM6QjN5UjI0ICZJJCL355W0IyZsZmIsIyM6FjMwIjI0IiclZnIsU6csFmZ0IiczRmI1pjIs9WQisnOiQkIsISP3ElbqNHNrZnbYh4RhhTU9hHRidjVNBVSVl6cpdVMItScwQnQ0B7M4gnQWJzVUJmSmt6VRNVQ9lkYy3iYyh5dGhDUxY7c4BFaRFkMNBjeFNlNzATW5pHdVhDU9IkbYZGORh4R7FXUjpEO9U6MhdXN9gzdBBHbxJnM7Y7VtZGOpZTZaZmb4RWQLt4VYZTYoV6cvlUWoR7S7clS9EDN8xWRx8kWORXMKJDUrRWSRJ6QnRmdaNHWrhmNVJDe9VkZx5mSaZVak3kWmZjYs34RlVVWSNTMw3SMGZGSSZXNYB7Mod6bwgGRyMlWqF7NvsmWrpWcwhmcKZnWxRWRod7L4QWSwg5M7l4bYNzSSxUa5g7LqBDMuRXOot6ZCdEMaN7Uv3yTo5EZHFWWFNjRvonbMVne8wmMtpleXFzM6h4UYJmYWhETIRlTkdTb52iTYFkNIpGO4dzdLR7ZIJiOiMlIsICNFdzNCVTQiojIIJCLyUTM7kjNxgjN0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsIiMxkDNyEDI7IDOwQjMwIjI0ICdyNkIsIici9SbvNmLwJXZjVGdzFWYz9Cbv56boJDcyVGLyJmLt36YuAnclNWZ4NXYhNnLyAnclxici9SbvNmLwJXZjVGdzFWYz9Cbv56bo9WatRWYsInYu46bj9CcyV6YlR7chF6cuIWdoxici9SbvNmLwJXZjVGdzFWYz9Cbv56bo56bjxici9SbvNmLwJXZjVGdzFWYz9SbvNGL4N7boxWYj3GbsInYu46bj9CcyV6YlR7chF6cu8WatRWYsInYu46bj9CcyV6YlR7chF6cuMHdy3GclJHLyJmLt36YuAnclNWZ4NXYhNnLzRncvBXZyh6YlR7bk5GLyJmLt36YuAnclNWZ4NXYhNSLN9",
);

export default { wjcCore };
