var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('drawer-opcoes',{attrs:{"prod":_vm.produtoSelecionadoObj,"editando":_vm.editandoDrawer}}),(_vm.abas.length > 0)?_c('v-tabs',{attrs:{"id":"tabContainer"},on:{"mouseover":_vm.tabHover},model:{value:(_vm.abaTabela),callback:function ($$v) {_vm.abaTabela=$$v},expression:"abaTabela"}},_vm._l((_vm.abas),function(aba,i){return _c('v-tab',{key:("aba-" + i),attrs:{"id":("aba-" + i)},on:{"mouseover":_vm.tabHover}},[_vm._v(" "+_vm._s(aba.nome_aba)+" "),_c('div',{staticStyle:{"margin-left":"4px"}},[_c('v-menu',{attrs:{"offset-y":"","top":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" edit ")])],1)]}}],null,true)},[_c('v-card',[_c('v-container',[_c('v-text-field',{attrs:{"maxlength":20},model:{value:(aba.nome_aba),callback:function ($$v) {_vm.$set(aba, "nome_aba", $$v)},expression:"aba.nome_aba"}})],1)],1)],1),_c('v-menu',{attrs:{"top":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" close ")])],1)]}}],null,true),model:{value:(aba.remove),callback:function ($$v) {_vm.$set(aba, "remove", $$v)},expression:"aba.remove"}},[_c('v-card',[_c('v-card-text',[_vm._v(" Tem certeza que deseja fechar esta pesquisa? ")]),_c('v-spacer'),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticStyle:{"margin":"3px"},attrs:{"x-small":"","outlined":"","color":"primary"},on:{"click":function($event){aba.remove = false}}},[_vm._v(" Não ")]),_c('v-btn',{staticStyle:{"margin":"3px"},attrs:{"x-small":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.retiraAba(i)}}},[_vm._v(" Sim ")])],1)],1)],1)],1)],1)])}),1):_vm._e(),(_vm.data.length === 0 && _vm.abas.length === 0)?_c('div',{staticClass:"filterMsg"},[_c('v-icon',[_vm._v(" error_outline ")]),_c('span',{staticClass:"filterWarning",style:(("" + (_vm.$vuetify.theme.dark ? 'color: #f0f0f5' : '')))},[_vm._v(" "+_vm._s(this.$t("AvisoFiltro")))])],1):(_vm.abas.length !== 0 && _vm.data.length === 0)?_c('div',{staticClass:"filterMsg"},[_c('v-icon',[_vm._v(" error_outline ")]),_c('span',{staticClass:"filterWarning"},[_vm._v(" Nenhum produto encontrado com os filtros inseridos")])],1):_vm._e(),(_vm.data.length > 0)?_c('div',{attrs:{"id":"hoverCell"}},[(!_vm.showPanel)?_c('wj-group-panel',{staticStyle:{"display":"block"},style:(("" + (_vm.$vuetify.theme.dark
          ? 'background-color: #2f2f2e; color: white'
          : 'background-color: rgba(0,0,0,.05); color: black'))),attrs:{"grid":_vm.flex,"placeholder":this.$t('ItensTabela.arrasteAqui')}}):_vm._e(),_c('form',{staticStyle:{"width":"100%"},attrs:{"id":"undoable-form"}},[(!_vm.$route.query.grupo)?_c('wj-flex-grid',{key:_vm.keyFlexGrid,staticClass:"grid",staticStyle:{"border-radius":"0"},style:(("" + (_vm.$vuetify.theme.dark
            ? 'background-color: #888'
            : 'background-color: #fff'))),attrs:{"items-source":_vm.data,"initialized":_vm.initializeGrid,"show-selected-headers":'All',"show-marquee":true,"sticky-headers":true,"allow-merging":'Cells',"alternating-row-step":0,"auto-generate-columns":false,"allow-pinning":"SingleColumn"}},[_c('wj-flex-grid-filter'),_vm._l((_vm.colunas),function(coluna,i){return _c('wj-flex-grid-column',{key:("coluna-" + i),attrs:{"header":coluna.cabecalho,"binding":coluna.valor,"min-width":coluna.minWidth,"width":coluna.larg,"format":coluna.format,"align":coluna.align,"col":coluna,"aggregate":coluna.aggregate,"visible":coluna.visible,"is-read-only":coluna.isReadOnly}})}),(_vm.acao)?_c('wj-flex-grid-column',{attrs:{"width":60,"header":this.$t('ItensTabela.acao'),"binding":"buttons"}}):_vm._e()],2):_c('wj-flex-grid',{key:_vm.keyFlexGrid,attrs:{"items-source":_vm.data,"initialized":_vm.initializeGrid,"child-items-path":['children']}},_vm._l((_vm.colunas),function(coluna,i){return _c('wj-flex-grid-column',{key:("coluna-" + i),attrs:{"header":coluna.cabecalho,"binding":coluna.valor,"width":coluna.larg,"format":coluna.format,"align":coluna.align}})}),1)],1)],1):_vm._e(),_c('div',{staticStyle:{"display":"none"},attrs:{"id":"tplBtnViewMode"}},[_c('v-btn',{staticStyle:{"font-size":"24px","margin-top":"-5px"},attrs:{"id":"btnDetails","icon":""}},[_vm._v(" + ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }