/* eslint-disable no-useless-escape */
export default {
  methods: {
    unmaskCpf(cpf) {
      return cpf.toString().replace('.', '').replace('.', '').replace('-', '')
    },
    unmaskTelCel(phone) {
      phone = phone.replace('(', '').replace(')', '').replace('-', '')
      return {
        ddd: phone.substring(0,2),
        number: phone.substring(3, phone.length),
      }
    },
    unmaskPorcentagem(valor) {
      if (valor.search('%') != -1) {
        valor = valor.replace('%', '')
        valor = valor.replace(',', '.')
      } else {
        valor = valor.replace(',', '.')
      }
    },
    unmaskBirth(birth) {
      return birth.getFullYear().toString() + (birth.getMonth() + 1).toString() + birth.getDate().toString()
    },
    unmaskCEP(cep) {
      return cep.toString().replace('.', '').replace('-', '')
    },
    unmaskCNPJ(cnpj) {
      return cnpj.toString().replace('.', '').replace('.', '').replace('/', '').replace('-', '')
    },
    unmask(value) {
      return value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
    },
    unmaskCurrency(value) { // R$ 30,00
      value = value.split(' ').length > 1 ? value.split(' ')[1] : value.split(' ')[1] // 2 tipos de espaço
      value = value.replace('.', '')
      value = value.replace(',','.')
      return value
    },
    backToISOFormat(date){
      const [dia, mes, ano] = date.split('/')
      return parseInt(`${ano}${mes}${dia}`)
    },
    monthYearFormat(date){
      const [mes, ano] = date.split('/')
      return parseInt(`${ano}${mes}`)
    },
    unmaskResidencePeriod(tempo){
      if(tempo){
        const ano = parseInt(tempo.split(' ')[0])
        const mes = parseInt(tempo.split(' ')[3])
        return {
          ano: ano,
          mes: mes,
        }
      }
      else{
        return null
      }
    },
  },
}
