var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('link',{attrs:{"href":"https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp","rel":"stylesheet"}}),_c('v-card-title',[_c('v-icon',[_vm._v(" info ")]),_c('span',{staticStyle:{"margin-left":"30px"}},[_vm._v(" Cadastro de ofertas ")])],1),_c('v-card-title',[_vm._v(" Filtros ")]),_c('v-slide-group',{staticClass:"pa-4",attrs:{"center-active":"","show-arrows":"","mandatory":""},model:{value:(_vm.filtroEscolhido),callback:function ($$v) {_vm.filtroEscolhido=$$v},expression:"filtroEscolhido"}},_vm._l((_vm.filtrosRapidos),function(filtro,i){return _c('v-slide-item',{key:("filtro-" + i),scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
var active = ref.active;
return [_c('v-card',{staticClass:"drawer_ofertas",style:({
          padding: '15px 0',
          borderBottom: '5px solid #f46434 !important',
        }),attrs:{"width":"100%","height":"120"},on:{"click":toggle}},[_c('v-row',{staticClass:"rowCentered"},[_c('i',{staticClass:"material-icons",style:({
              color: ((active ? '#f46434' : '#666') + " !important"),
              fontSize: '48px',
            })},[_vm._v(" "+_vm._s(filtro.tc010_im_filtro)+" ")])]),_c('v-row',{staticClass:"rowCentered"},[_c('span',{staticClass:"ofertas"},[_vm._v(_vm._s(filtro.tc010_ds_filtro))])])],1)]}}],null,true)})}),1),_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.formOferta),callback:function ($$v) {_vm.formOferta=$$v},expression:"formOferta"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"label":"Cód. barras","outlined":"","dense":"","rules":_vm.regraEscolherCdBarras,"hint":"Dica: para encontrar o cód. barras mais rápido, use um scanner","items":_vm.arrayCdBarras},model:{value:(_vm.cdBarras),callback:function ($$v) {_vm.cdBarras=$$v},expression:"cdBarras"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.porcentagem),expression:"porcentagem"}],key:_vm.keyDesc,attrs:{"label":"Desconto","type":"tel","outlined":"","prepend-inner-icon":_vm.porcentagemBoolean ? 'attach_money' : 'percent',"append-icon":"import_export","dense":""},on:{"click:append":_vm.changeValue},model:{value:(_vm.PcDesconto),callback:function ($$v) {_vm.PcDesconto=$$v},expression:"PcDesconto"}}),_c('v-divider'),_c('v-card-title',[_vm._v(" Término do desconto ")]),_c('v-card-subtitle',[_vm._v(" O desconto será aplicado assim que salvas as configurações ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu",attrs:{"transition":"scale-transition","offset-y":"","min-width":"auto","close-on-content-click":_vm.dataSelecionada},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-combobox',_vm._g(_vm._b({attrs:{"label":"Data","outlined":"","dense":"","readonly":"","rules":_vm.regraEscolherData},on:{"click":function($event){_vm.dataSelecionada = false}},model:{value:(_vm.dataFinalFormatada),callback:function ($$v) {_vm.dataFinalFormatada=$$v},expression:"dataFinalFormatada"}},'v-combobox',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"scrollable":"","min":_vm.dataInicial},on:{"click:date":function($event){_vm.dataSelecionada = true}},model:{value:(_vm.dataFinal),callback:function ($$v) {_vm.dataFinal=$$v},expression:"dataFinal"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu",attrs:{"transition":"scale-transition","offset-y":"","min-width":"auto","close-on-content-click":_vm.minutoSelecionado},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-combobox',_vm._g(_vm._b({attrs:{"label":"Horário","outlined":"","dense":"","readonly":"","rules":_vm.regraEscolherHorario},on:{"click":function($event){_vm.minutoSelecionado = false}},model:{value:(_vm.horaFinal),callback:function ($$v) {_vm.horaFinal=$$v},expression:"horaFinal"}},'v-combobox',attrs,false),on))]}}])},[_c('v-time-picker',{attrs:{"format":"24hr","scrollable":""},on:{"click:minute":function($event){_vm.minutoSelecionado = true}},model:{value:(_vm.horaFinal),callback:function ($$v) {_vm.horaFinal=$$v},expression:"horaFinal"}})],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"rowCentered-prod"},[_c('v-btn',{staticStyle:{"margin-right":"10px"},attrs:{"color":"primary","text":"","disabled":!_vm.formOferta},on:{"click":_vm.salvaDesconto}},[_vm._v(" Salvar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }