export default {
    data() {
        return {
            micRecording: false,
            audioChunks: [],
            audio: {},
            mediaRecorder: null,
        }
    },
    methods: {
        handleMicRecord() {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(stream => {
                    this.mediaRecorder = new MediaRecorder(stream);
                    this.mediaRecorder.start();

                    this.audioChunks = []

                    this.mediaRecorder.addEventListener("dataavailable", event => {
                        this.audioChunks.push(event.data);
                    });

                    this.micRecording = true
                })
                .catch((error) => {
                    this.notification().error({erro: error.toString() })
                });
        },
        async handleStopMicRecord() {
            this.mediaRecorder.onstop = async () => {
                const audioBlob = new Blob(this.audioChunks)
                const audioUrl = URL.createObjectURL(audioBlob);

                this.audio = {
                    audioBlob,
                    audioUrl,
                }

                this.micRecording = false
            };

            this.mediaRecorder.stop()

            const audio = await new Promise((resolve) => {
                setTimeout(async () => {
                    resolve(this.audio)
                }, 100);
            })

            return audio
        },
    },
}