export default {
  methods: {
    async chamaSP(SP, filtros){
        let usuario = await this.getUser()
        /* Obj padrão necessário para que a requisição no banco seja feita */
        const obj = {filtros}
        
        return await this.$wso2.get({'functionName':`${SP}`,'server': 'DW', 'versao': '1'}, usuario.base.ce040_nr_inst, '36', usuario.base.us01_nm_login.toLowerCase() ,obj)
    },
    async chamaMulti(SP, filtros){
      /* Pega os dados da sessão */
      let usuario = await this.getUser()
      /* Obj padrão necessário para que a requisição no banco seja feita */
      const obj = filtros
      
      return await this.$wso2.get({'functionName':`${SP}`,'server': 'DW', 'versao': '1'}, usuario.base.ce040_nr_inst, '36', usuario.base.us01_nm_login.toLowerCase() ,obj)
  },
    async postSP(SP, filtros) {
        /* Pega os dados da sessão */
        let usuario = await this.getUser()
        /* Obj padrão necessário para que a requisição no banco seja feita */
        const obj = filtros

        return await this.$wso2.post({'functionName':`${SP}`,'server': 'DW', 'versao': '1'}, usuario.base.ce040_nr_inst, '36', usuario.base.us01_nm_login.toLowerCase() ,obj)
    },
    async getUser(){
      return this.$wso2.getUserData()
    },
  },
}