import { Selector } from "@grapecity/wijmo.grid.selector";
// Imports wijmo
import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.touch"; // support drag/drop on touch devices
import "@grapecity/wijmo.vue2.grid";
import "@grapecity/wijmo.vue2.grid.filter";
import "@grapecity/wijmo";
import "@grapecity/wijmo.grid.filter";
import "@grapecity/wijmo.vue2.grid.grouppanel";
import "@grapecity/wijmo.input";
import * as wjcCore from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid";

// Componentes locais
import drawerOpcoes from "../drawerOpcoes";
import { head } from "lodash";

export default {
  components: { drawerOpcoes },
  model: {
    prop: "dados",
    event: "change",
  },
  data() {
    return {
      showPanel: false,
      abaTabela: 0,
      flex: {},
      wjcGrid,
      moveDadosAba: 0,
      tamanArrayAbas: 0,
      keyFlexGrid: 0,
      selector: null,
      nomeAbaSelecionada: "",
      selectedItems: [],
      produtoSelecionado: [],
      produtoSelecionadoObj: {},
      editandoDrawer: false,
      data: [],
    };
  },
  mounted() {
    this.data = this.dados;
    this.mudaTema();
  },
  watch: {
    dados: {
      deep: true,
      handler() {
        this.data = this.dados;
      },
    },
    data: {
      deep: true,
      handler() {
        this.$emit("change", this.data);
      },
    },
    abaTabela: {
      handler() {
        this.mudaTabela(this.abaTabela);
      },
    },
    abas: {
      deep: true,
      handler() {
        if (this.abas.length > this.tamanArrayAbas) {
          this.abaTabela = this.abas.length - 1;
          this.tabelaEscolhida(this.abaTabela);
        }
        this.tamanArrayAbas = this.abas.length;
      },
    },
    isDark() {
      this.mudaTema();
    },
  },
  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
  },
  props: {
    chaveUnicaObjeto: {
      type: String,
      default: "ce119_cd_barras",
      required: true,
    },
    acao: {
      type: Boolean,
      required: false,
      default: false,
    },
    colunas: {
      // Array de colunas
      type: Array,
      default: () => {
        return [];
      },
      required: true,
    },
    dados: {
      // Array de dados
      type: Array,
      default: () => {
        return [];
      },
      required: true,
    },
    abas: {
      type: Array,
      default: () => {
        return [];
      },
      required: true,
    },
    htmlTabelaMobile: {
      type: String,
      default:
        '<div class="item-header">{ce110_ds_prod}</div>' +
        '<div class="item-detail">Vlr. compra: {ce110_vl_compra}, Vlr. venda: {ce110_vl_venda}</div>' +
        '<div class="item-detail">Grupo: {ce113_ds_grp}, Dep: {ce175_ds_depto}, Seção: {ce176_ds_secao}, Coleção: {ce178_ds_colec}</div>',
    },
  },
  methods: {
    retiraAba(index) {
      // retira a aba
      this.abas.splice(index, 1);
      if (this.abas.length === 0) {
        // reseta o filtro
        this.data = [];
      }
    },
    mudaTema() {
      let style = null;
      style = document.createElement("style");
      document.head.appendChild(style);
      if (this.isDark) {
        style.innerHTML = `
      .wj-flexgrid .wj-cell{
        background-color: #2f2f2e;
        color: #f0f0f5;
        border-color: #000;
      }`;
      } else {
        style.innerHTML = `
        .wj-flexgrid .wj-cell{
          background-color: #f0f0f5;
          color: #000;
          border-color: #999999;
        }`;
      }
      style = null;
    },
    tabHover(e) {
      if (e.target.id === "tabContainer") {
        this.moveDadosAba = this.abas.length + 1;
      } else if (e.target.id.includes("aba-")) {
        this.moveDadosAba = parseInt(e.target.id.split("-")[1]);
      }
      if (e.target.id) {
        this._makeDropTarget(document.querySelector("#" + e.target.id));
      }
    },
    // make grid rows draggable
    _makeDragSource: function(s) {
      // make rows draggable
      s.itemFormatter = (panel, r, c, cell) => {
        if (panel.cellType == this.wjcGrid.CellType.RowHeader) {
          cell.textContent = (r + 1).toString();
          cell.draggable = true;
        }
      };
      // disable built-in row drag/drop
      s.addEventListener(
        s.hostElement,
        "mousedown",
        (e) => {
          if (s.hitTest(e).cellType == this.wjcGrid.CellType.RowHeader) {
            e.stopPropagation();
          }
        },
        true,
      );
      // handle drag start
      s.addEventListener(
        s.hostElement,
        "dragstart",
        (e) => {
          let ht = s.hitTest(e);
          if (ht.cellType == this.wjcGrid.CellType.RowHeader) {
            s.select(
              new wjcGrid.CellRange(ht.row, 0, ht.row, s.columns.length - 1),
            );
            e.dataTransfer.effectAllowed = "copy";
            e.dataTransfer.setData("text", ht.row.toString());
          }
        },
        true,
      );
    },
    // enable drop operations on an element
    _makeDropTarget: function(s) {
      s.addEventListener("dragover", (e) => {
        let dragRow = e.dataTransfer.getData("text");
        if (dragRow != null) {
          e.dataTransfer.dropEffect = "copy";
          e.preventDefault();
        }
      });
      s.addEventListener("drop", (e) => {
        let dragRow = e.dataTransfer.getData("text");
        if (dragRow != null) {
          let item = this.flex.rows[parseInt(dragRow)].dataItem;
          let dados;
          if (item._items) {
            dados = {
              aba: this.moveDadosAba,
              items: [...item._items],
            };
          } else {
            dados = {
              aba: this.moveDadosAba,
              items: [{ ...item }],
            };
          }
          this.$emit("move-dados", dados, this.nomeAbaSelecionada);
          this.mudaTabela(this.moveDadosAba);
          e.preventDefault();
        }
      });
    },
    mudaTabela(aba) {
      // vai para a nova aba criada
      this.data = this.abas[aba].pesquisa;
    },
    tabelaEscolhida(aba) {
      const idx = this.abas[aba].nome_aba.indexOf(":") + 1;
      this.abas[aba].nome_aba =
        this.abas[aba].nome_aba.substring(0, idx) +
        this.abas[aba].pesquisa.length;
      this.nomeAbaSelecionada = this.abas[aba].nome_aba;
      this.$emit("aba-selecionada", this.nomeAbaSelecionada);
    },
    initializeGrid(flex) {
      /* this.flex = flex;
      this.flex.columnFooters.rows.push(new wjcGrid.GroupRow());
      this.flex.bottomLeftCells.setCellData(0, 0, "S");
      this.flex.selectionMode = "RowRange";
      this.flex.formatItem.addHandler((s, e) => {
        // adiciona o simbolo da engrenagem na tabela, assim como a cor
        if (e.panel == s.topLeftCells) {
          e.cell.innerHTML =
            '<span class="column-picker-icon fa fa-gears" style="color: #ff8754"> </span>';
        }
      }); */
      if (flex != null) {
        const vm = this;
        this.flex = flex;
        flex.rows.defaultSize = 25;
        flex.cells.rows.defaultSize = 25; // same thing (cells is the default panel)
        flex.columnHeaders.rows.defaultSize = 25; // default size for header rows

        this._makeDragSource(this.flex);

        this._defaultRowHeight = this.flex.rows.defaultSize;

        this.selector = new Selector(flex, {
          itemChecked: () => {
            this.selectedItems = flex.rows
              .filter((r) => r.isSelected)
              .map((p) => {
                return {
                  ...p,
                  dataItem: {
                    ...p.dataItem,
                    isSelected: p.isSelected,
                  },
                };
              });

            this.data = this.dados.map((p) => {
              // A próxima linha força com que o ID do objeto seja o primeiro registro
              const selected = !!this.selectedItems.find(
                (f) =>
                  f.dataItem[this.chaveUnicaObjeto] === p[this.chaveUnicaObjeto],
              );
              p.isSelected = selected;
              return p;
            });
          },
        });

        // custom formatter to paint buttons and editors
        flex.formatItem.addHandler((s, e) => {
          // show company summary on narrow layout
          if (
            e.panel == s.cells &&
            e.col == 1 &&
            this.flex.hostElement.clientWidth < 600
          ) {
            // eslint-disable-next-line no-undef
            let html = wijmo.format(
              vm.htmlTabelaMobile,
              s.rows[e.row].dataItem,
            );
            e.cell.innerHTML = html;
          }

          if (e.panel == s.cells) {
            let col = s.columns[e.col],
              item = s.rows[e.row].dataItem;
            if (item == this._currentEditItem) {
              // create editors and buttons for the item being edited
              switch (col.binding) {
                case "buttons":
                  e.cell["dataItem"] = item;
                  break;
              }
            } else {
              // create buttons for items not being edited
              switch (col.binding) {
                case "buttons":
                  e.cell.innerHTML = document.getElementById(
                    "tplBtnViewMode",
                  ).innerHTML;
                  e.cell["dataItem"] = item;
                  break;
              }
            }
          }
        });

        // handle button clicks
        flex.addEventListener(flex.hostElement, "click", (e) => {
          let targetBtn;
          if (e.target instanceof HTMLButtonElement) {
            targetBtn = e.target;
          } else if (
            e.target instanceof HTMLSpanElement &&
            e.target.classList.contains("v-btn__content")
          ) {
            targetBtn = e.target.parentElement;
          }
          if (targetBtn) {
            // get button's data item
            let item = wjcCore.closest(targetBtn, ".wj-cell")["dataItem"];
            // handle buttons
            switch (targetBtn.id) {
              case "btnDetails":
                this.produtoSelecionado = [];
                this.editandoDrawer = true;
                this.produtoSelecionado.push(item);
                this.produtoSelecionadoObj = item;
                this.openMenuProdutos();
                break;
              // start editing this item
              case "btnEdit":
                this.colunas[0].larg = "2.2*";
                this.produtoSelecionado = item;
                break;
              // remove this item from the collection
              case "btnDelete":
                flex.collectionView.remove(item);
                break;
              // commit edits
              case "btnCerto":
                this._commitEdit();
                break;
              // cancel edits
              case "btnCancel":
                this._cancelEdit();
                break;
            }
          }
        });

        this.$emit("flexgrid", this.flex);

        /*// exit edit mode when scrolling the grid or losing focus
        flex.scrollPositionChanged.addHandler(this._cancelEdit.bind(this));
        flex.lostFocus.addHandler(this._cancelEdit.bind(this));
    
        this._updateGridLayout()
        this.$emit('flexgrid', this.flex) */
      } else {
        this.flex = false;
        this.$emit("flexgrid", this.flex);
      }
    },
    _updateGridLayout: function() {
      // show/hide columns
      let narrow = this.flex.hostElement.clientWidth < 600;
      this.flex.columns.forEach((col) => {
        col.visible = col.index == 1 ? narrow : !narrow;
      });
      this.showPanel = narrow;
      // make rows taller on phone layout
      this.flex.rows.defaultSize = this._defaultRowHeight * (narrow ? 3 : 1);
      // hide column headers on narrow layouts
      this.flex.headersVisibility = narrow
        ? this.wjcGrid.HeadersVisibility.None
        : this.wjcGrid.HeadersVisibility.All;
    },
    _commitEdit() {
      if (this._currentEditItem) {
        this.flex.columns.forEach((col) => {
          let input = this.flex.hostElement.querySelector("#" + col.binding);
          if (input) {
            let value = wjcCore.changeType(
              input.value,
              col.dataType,
              col.format,
            );
            if (wjcCore.getType(value) == col.dataType) {
              this._currentEditItem[col.binding] = value;
            }
          }
        });
        this.salvaEdicaoProdutosLinha();
      }
      this._currentEditItem = null;
      this.flex.invalidate();
    },
    _cancelEdit() {
      if (this._currentEditItem) {
        this._currentEditItem = null;
        this.flex.invalidate();
      }
    },
    isColumnReadonly(cabecalho) {
      return (
        cabecalho === "Grupo" ||
        cabecalho === "Departamento" ||
        cabecalho === "Seção" ||
        cabecalho === "Coleção" ||
        cabecalho === "Situação" ||
        cabecalho === "Im. prod"
      );
    },
  },
};
