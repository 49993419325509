<template>
  <div>
    <v-row>
      <v-col cols="2">
        <v-text-field
          v-model="codigo"
          outlined
          dense
          label="Código"
        />
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-model="input"
          outlined
          dense
          label="Descrição"
        />
      </v-col>
      <v-col cols="2">
        <v-btn
          color="primary"
          text
          width="100%"
          @click="salvar"
        >
          {{ this.$t("BotoesAcao.salvar") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    nome: {
      // Para qual sp será mandada com base no nome
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      codigo: "",
      input: "",
      storeProcedure: "",
    };
  },
  mounted() {
    this.codigo = Math.max(
      ...this.items.map((f) => {
        let chave = Object.keys(f)[0];
        return f[chave] + 1;
      }),
    );
  },
  methods: {
    async salvar() {
      let usuario = await this.getUser();
      let ent = {
        _rTABELA: 0, // 1- secao 2- departamento 3- colecao
        _rNOVO: 1, // 1-novo 2-editar
        _rCD_ESCOLHIDO: this.codigo, // codigo
        _rDS_ESCOLHIDO: this.input, // descricao
        _rCD_OPESIS: usuario.base.us01_nr_usr, // usuario
      };
      switch (this.nome) {
      case this.$t("Ferramentas.secao.secaoNome"):
        ent._rTABELA = 1;
        break;
      case this.$t("Ferramentas.departamento.deptoNome"):
        ent._rTABELA = 2;
        break;
      case this.$t("Ferramentas.colecao.colecaoNome"):
        ent._rTABELA = 3;
        break;
      case this.$t("Ferramentas.grupo.grupoNome"):
        this.grupo = true;
      }
      try {
        this.request({
          metodo: "get",
          sp: "SP_CE360901019",
          params: ent,
        });
        this.notification().success( {mensagem: `${this.nome} ${this.$t("notificacao.salvo")}` } );
        this.$emit("salvo");
      } catch (err) {
        this.notification().error({erro: err});
      }
    },
  },
};
</script>

<style>
</style>