<template>
  <div>
    <v-file-input
      id="foto"
      type="file"
      accept="image/*"
      style="display: none"
      @change="reciveFoto"
    />
    <v-card-title>
      <v-icon> information </v-icon>
      <span style="margin-left: -150px"> Manutenção de imagem do produto </span>
    </v-card-title>
    <div
      v-if="fotoProduto.length != 0"
      style="width: 100%"
    >
      <v-badge
        color="green"
        :content="fotoProduto.length"
        style="float: right; right: 50px; top: 85px; z-index: 1"
      />
    </div>
    <div
      v-if="fotoProduto.length == 0"
      class="CenteredDiv"
      style="height: 350px"
    >
      <span>Esse produto ainda não possui uma foto</span>
    </div>
    <div
      v-else
      class="CenteredDiv"
    >
      <v-carousel
        v-model="posicao"
        height="400"
        style="max-width: 400px"
        hide-delimiters
        @change="posicao = $event"
      >
        <v-carousel-item
          v-for="(foto, i) in fotoProduto"
          :key="i"
          ref="carrosel"
          :src="
            foto.ce107_im_arq.substring(0, 4) == 'data'
              ? foto.ce107_im_arq
              : `data:image/png;base64,${foto.ce107_im_arq}`
          "
          style="padding: 20px"
        />
      </v-carousel>
    </div>
    <v-row style="padding: 0 25px">
      <v-col
        cols="12"
        sm="6"
      >
        <v-card
          width="100%"
          height="200"
          flat
          style="border-radius: 30px"
          @click="uploadFoto(true)"
        >
          <v-row class="rowCentered-prod">
            <v-col style="margin-top: 30px">
              <v-icon size="56">
                add_to_photos
              </v-icon>
            </v-col>
            <v-col cols="12">
              <span>Adicionar uma nova foto</span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-card
          width="100%"
          height="200"
          flat
          style="border-radius: 30px"
          @click="uploadFoto(false)"
        >
          <v-row class="rowCentered-prod">
            <v-col style="margin-top: 30px">
              <v-icon size="56">
                edit
              </v-icon>
            </v-col>
            <v-col cols="12">
              <span>Editar foto</span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <div class="CenteredDiv">
        <v-col
          cols="12"
          sm="6"
        >
          <v-card
            width="100%"
            height="200"
            flat
            style="border-radius: 30px"
            @click="removerFoto"
          >
            <v-row class="rowCentered-prod">
              <v-col style="margin-top: 30px">
                <v-icon size="56">
                  delete
                </v-icon>
              </v-col>
              <v-col cols="12">
                <span>Remover foto</span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    produto: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      fotoProduto: [],
      posicao: 0,
      adicionando: false,
      fileFoto: {},
    };
  },
  mounted() {
    this.configFoto();
  },
  methods: {
    configFoto() {
      this.produto.ce107_im_arq.forEach((f) => {
        if (f.ce107_im_arq !== null && f.ce107_nr_arq !== null) {
          this.fotoProduto.push({
            ce107_im_arq: f.ce107_im_arq,
            ce107_nr_arq: f.ce107_nr_arq,
          });
        }
      });
    },

    async adicionarFoto(fotoBase64, nomeFoto) {
      let usuario = await this.getUser();
      let valEnt = {
        _rCD_PROD: this.produto.ce110_cd_prod,
        _rIM_ARQ: fotoBase64,
        _rNM_ARQ: nomeFoto,
        _rCD_OPESIS: usuario.base.us01_nr_usr,
      };
      try {
        // this.loading = true
        await this.request({
          metodo: 'post',
          sp: 'SP_CE360901013',
          params: valEnt,
        });
        this.notification().success({mensagem:"Foto adicionada com sucesso"});
      } catch (e) {
        this.notification().error({erro: "Algo deu errado no envio da foto!"});
      } finally {
        let ult = 0;
        this.fotoProduto.forEach((f) => {
          f.ce107_nr_arq > ult ? (ult = f.ce107_nr_arq) : false;
        });
        this.fotoProduto.push({
          ce107_im_arq: fotoBase64,
          ce107_nr_arq: ult + 1,
        });
        this.posicao = this.fotoProduto.length - 1;
      }
    },
    async editarFoto(fotoBase64, nomeFoto) {
      let usuario = await this.getUser();
      let valEnt = {
        _rCD_PROD: this.produto.ce110_cd_prod,
        _rIM_ARQ: fotoBase64,
        _rNM_ARQ: nomeFoto,
        _rNR_ARQ: this.fotoProduto[this.posicao].ce107_nr_arq,
        _rCD_OPESIS: usuario.base.us01_nr_usr,
      };
      try {
        await this.postSP("SP_CE360901014", [valEnt]);
        this.notification().success({mensagem:"Foto editada com sucesso"});
      } catch (e) {
        this.notification().error({erro: "Algo deu errado no envio da foto!"});
      } finally {
        this.fotoProduto[this.posicao].ce107_im_arq = fotoBase64;
      }
    },
    async removerFoto() {
      let valEnt = {
        _rCD_PROD: this.produto.ce110_cd_prod,
        _rNR_ARQ: this.fotoProduto[this.posicao].ce107_nr_arq,
      };
      try {
        await this.postSP("SP_CE360901015", [valEnt]);
        this.notification().success({mensagem:"Foto removida com sucesso"});
      } catch (e) {
        this.notification().error({erro: "Erro ao deletar a foto"});
      } finally {
        this.fotoProduto.splice(this.posicao, 1);
      }
    },
    uploadFoto(valor) {
      document.getElementById("foto").click();
      this.adicionando = valor;
    },
    async reciveFoto(foto) {
      let fotoBase64 = await this.imageToBase64(foto);
      fotoBase64 = fotoBase64.split(",")[1];
      if (this.adicionando) {
        this.adicionarFoto(fotoBase64, foto.name);
      } else {
        this.editarFoto(fotoBase64, foto.name);
      }
      document.getElementById("foto").value = "";
    },
  },
};
</script>

<style>
.CenteredDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.v-image__image--cover {
  background-size: cover !important;
}
</style>