import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { pt, en, es } from "vuetify/lib/locale";
import "vuetify/dist/vuetify.min.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#f16136",
        secondary: "#fff",
        accent: "#fc7854",
        error: "#b71c1c",
      },
      dark: {
        primary: "#f16136",
        secondary: "#fff",
        accent: "#fc7854",
        error: "#b71c1c",
      },
    },
  },
  lang: {
    locales: { pt, en, es },
    current: "pt",
  },
  icons: {
    iconfont: "md",
  },
});
