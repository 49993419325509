import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState(['dialogoMulti']),
  },
  methods: {
    ...mapActions(['vuexSet']),
    openDialog() {
      this.vuexSet({ value: true, stateName: 'dialogoMulti' })
    },
    closeDialog() {
      this.vuexSet({ value: false, stateName: 'dialogoMulti' })
    },
  },
}