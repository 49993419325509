/**
 * Métodos do componente Ferramnetas.
 * @module CadastroProduto
 * @typicalname methods
 */
import { mapActions } from "vuex";
export default {
  ...mapActions(["vuexSet"]),
  abreDrawer(produto) {
    this.selectOpcao = 0;
    this.prod = produto;
  },
  novoProduto(prod) {
    this.$emit("novo", prod);
    this.limpaCampos();
    this.$emit("change", false);
  },
  editadoProduto(prod) {
    this.$emit("editado", prod);
  },
  async validate() {
    const formValido = await this.validateForm();
    if (formValido) {
      this.campos.forEach((c) => {
        c.fields.forEach((f) => {
          if (f.retorno && typeof f.retorno == "object") {
            Object.keys(f.retorno).forEach((chave) => {
              this.prod[chave] = f.retorno[chave];
            });
          } else this.prod[f.chave] = f.model;
        });
      });
      this.prod.imagens = this.imageList.map(it => {
        return {
          ...it,
          nr_princ: it.nr_princ ? 1 : 2,
          im_arq: it.uri.split('base64,')[1],
          nm_arq: it.name,
        }
      })
      this.editando
        ? this.editadoProduto(this.prod)
        : this.novoProduto(this.prod);
    } else {
      this.notification().error({erro: "Por favor preencha todos os campos"});
    }
    if (this.editando && this.produtos.length == 1) {
      setTimeout(() => {
        this.modelProp = !this.modelProp;
      }, 500);
    }
  },
  limpaCampos() {
    this.campos.forEach((divisao) => {
      divisao.fields.forEach((campo) => {
        campo.model = null;
      });
    });
  },
  insereDadosCampos() {
    const vm = this;
    if (this.prod.ce107_im_arq) {
      this.prod.ce107_im_arq = this.prod.ce107_im_arq.map(it => {
        return {
          ...it,
          nr_princ: it.ce107_nr_princ == 1 ? true : false,
          name: it.ce107_nm_arq,
          type: 'image/png',
          uri: it.ce107_im_arq,
          im_arq: it.ce107_im_arq.split('base64,')[1],
        }
      })
      this.imageList = this.prod.ce107_im_arq
    }
    this.campos.forEach((div) => {
      div.fields.forEach((c) => {
        c.model = vm.prod[c.chave];
        c.hint = `Valor atual - ${
          vm.prod[c.chave] ? vm.prod[c.chave] : "Sem valor atual"
        }`;
      });
    });
    this.keyCadastroForm++;
  },
  openInput() {
    const el = document.querySelector('#inputImage')
    if (el) {
      el.value = ''
      el.click()
    }
  },
  uploadImage(e) {
    for (let item of e.target.files) {
      this.buildImage(item)
    }
  },
  buildImage(inputedImage) {
    const reader = new FileReader()
    let base64
    reader.onloadend = () => {
      base64 = reader.result
      const obj = {
        name: inputedImage.name,
        type: inputedImage.type,
        uri: base64,
        nr_princ: false,
      }
      this.imageList.push(obj)
    }
    reader.readAsDataURL(inputedImage)
  },
  handleImagemPrincipal(key) {
    let imagens = this.imageList.map(it => {
      return { ...it, nr_princ: false }
    })
    imagens[key].nr_princ = true

    this.imageList = imagens
  },
  removeImagem(index) {
    this.imageList.splice(index, 1)
  },
  addEventsToDropArea() {
    if (this.isEditing) return
    const dropArea = document.querySelector('#droparea')
    dropArea.addEventListener('dragenter', this.startedDrag, false)
    dropArea.addEventListener('dragleave', this.leavedDrag, false)
    dropArea.addEventListener('dragover', this.draggingOver, false)
    dropArea.addEventListener('drop', this.dropItem, false)
  },
  startedDrag(e) {
    e.preventDefault()
    e.stopPropagation()
    this.isDragging = true
  },
  leavedDrag(e) {
    e.preventDefault()
    e.stopPropagation()
    this.isDragging = false
  },
  draggingOver(e) {
    e.preventDefault()
    e.stopPropagation()
    this.isDragging = true
  },
  dropItem(ev) {
    ev.preventDefault()
    ev.stopPropagation()
    if (ev.dataTransfer.items) {
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          var file = ev.dataTransfer.items[i].getAsFile()
          if (this.allowedTypes.includes(file.type)) {
            this.buildImage(file)
          } else {
            this.notification().warn({
              mensagem: `Os formatos permitidos são .png, .jpg, .jpeg, .pdf`,
            })
          }
        }
      }
    }
    this.isDragging = false
  },
};
