export default {
  methods: {
    escolheRegex(regex) {
      const escolha = (reg) => ({
        "number": /(\D)/g,
        "cpf": /\d{3}\.?\d{3}\.?\d{3}-?\d{2}/,
      })[reg]
    
      return escolha(regex)
    },
  },
}