<template>
  <div
    v-if="!transionend"
    id="splash"
  >
    <img
      id="logo"
      src="logoDark.png"
      alt="Logo"
    >
    <v-row
      style="display: flex; justify-content: center; justify-items: center"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      transionend: false,
    };
  },
  created() {
    if (!this.transionend) {
      window.addEventListener("DOMContentLoaded", function () {
        document
          .querySelector("#splash")
          .addEventListener("transitionend", (event) => {
            event.target.remove();
            this.transionend = true;
          });
        requestAnimationFrame(() => {
          document.querySelector("#splash").classList.add("animate");
          //navigator.vibrate([300, 300, 300, 300, 300, 300, 300, 300, 300])
        });
      });
    }
  },
};
</script>

<style lang="scss" src="./style.scss" />