<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      right
      fixed
      temporary
      :width="$vssWidth > 780 ? (selectOpcao == 0 ? '280px' : '45%') : '100%'"
    >
      <v-icon
        v-if="selectOpcao == 0"
        style="padding: 10px"
        @click="resetDrawer"
      >
        close
      </v-icon>
      <v-icon
        v-else
        style="padding: 10px"
        @click="selectOpcao = 0"
      >
        arrow_back
      </v-icon>
      <div
        v-if="selectOpcao == 0 && editando"
        class="rowcentered-prod"
      >
        <v-card-title>O que deseja fazer com o produto?</v-card-title>
        <v-row style="margin: 0 15px">
          <!-- <v-col - Arrumar passar prop campos
            cols="12"
            style="padding:4px"
          >
            <v-card
              width="100%"
              height="190"
              color="#eeeeee"
              flat
              style="border-radius: 30px"
              @click="selectOpcao = 1"
            >
              <v-row class="rowCentered-prod">
                <v-col style="margin-top: 30px">
                  <v-icon size="56">
                    edit
                  </v-icon>
                </v-col>
                <v-col cols="12">
                  <span>Editar produto</span>
                </v-col>
              </v-row>
            </v-card>
          </v-col> -->
          <v-col
            cols="12"
            style="padding: 4px"
          >
            <v-card
              width="100%"
              height="190"
              flat
              style="border-radius: 30px"
              @click="selectOpcao = 2"
            >
              <v-row class="rowCentered-prod">
                <v-col style="margin-top: 30px">
                  <v-icon size="56">
                    qr_code_2
                  </v-icon>
                </v-col>
                <v-col cols="12">
                  <span>Cadastrar código de barras</span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            style="padding: 4px"
          >
            <v-card
              width="100%"
              height="190"
              flat
              style="border-radius: 30px"
              @click="selectOpcao = 3"
            >
              <v-row class="rowCentered-prod">
                <v-col style="margin-top: 30px">
                  <v-icon size="56">
                    local_offer
                  </v-icon>
                </v-col>
                <v-col cols="12">
                  <span>Cadastrar oferta</span><br>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            style="padding: 4px"
          >
            <v-card
              width="100%"
              height="190"
              flat
              style="border-radius: 30px"
              @click="selectOpcao = 4"
            >
              <v-row class="rowCentered-prod">
                <v-col style="margin-top: 30px">
                  <v-icon size="56">
                    add_photo_alternate
                  </v-icon>
                </v-col>
                <v-col cols="12">
                  <span>Cadastrar imagem</span><br>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div
        v-else-if="selectOpcao == 0 && !editando"
        class="rowcentered-prod"
      >
        <v-card-title>Selecione uma opção</v-card-title>
        <v-row style="margin: 0 15px">
          <v-col
            cols="12"
            style="padding: 4px"
          >
            <v-card
              width="100%"
              height="200"
              flat
              style="border-radius: 30px"
              @click="selectOpcao = 1"
            >
              <v-row class="rowCentered-prod">
                <v-col style="margin-top: 30px">
                  <v-icon size="56">
                    add
                  </v-icon>
                </v-col>
                <v-col cols="12">
                  <span>Adicionar produto</span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else-if="selectOpcao == 1">
        <cadastro-pd
          :produto="[prod]"
          :editando="editing"
        />
      </div>
      <div v-else-if="selectOpcao === 2">
        <cadastro-cd :produto="prod" />
      </div>
      <div v-else-if="selectOpcao === 3">
        <cadastrar-oferta :produto="prod" />
      </div>
      <div v-else-if="selectOpcao === 4">
        <cadastrar-imagem :produto="prod" />
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import cadastroPd from "../cadastroProduto/";
import cadastrarOferta from "../CadastrarOferta/";
import cadastrarImagem from "../cadastroImagem/";
import cadastroCd from "../cadastroCdBarras/";
import { mapState } from "vuex";
export default {
  components: {
    cadastroPd,
    cadastrarOferta,
    cadastrarImagem,
    cadastroCd,
  },
  props: {
    prod: {
      type: Object,
      require: true,
      default: () => {
        return {};
      },
    },
    editando: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  data() {
    return {
      opcaoEscolhida: 0,
      search: "",
      drawer: false,
      produtoSelecionado: {},
      loadingGrupo: false,
      selectOpcao: 0,
      dados: [],
      loading: false,
      nomeFoto: "",
      fotoAction: [],
      habilitaCamposEdicao: false,
    };
  },
  computed: {
    ...mapState(["drawerProdutos"]),
    editing() {
      return this.editando;
    },
  },
  watch: {
    drawerProdutos() {
      this.drawer = this.drawerProdutos;
    },
    drawer() {
      if (!this.drawer) {
        this.resetDrawer();
      }
    },
  },
  methods: {
    resetDrawer() {
      this.selectOpcao = 0;
      this.closeMenuProdutos();
    },
  },
};
</script>

<style>
.col-12 {
  min-height: 90px;
}
.filterProd {
  padding: 15px 0;
}
.rowCentered-prod {
  margin: 0px;
  align-items: center;
  justify-content: center;
  height: 75px;
  text-align: center;
}
.v-window__prev {
  margin: 0 !important;
}
.v-window__next {
  margin: 0 !important;
}
.buttonsFoto {
  position: absolute;
  bottom: 0px;
  background: #0000009e;
  width: 100%;
}
</style>