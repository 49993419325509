import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../layouts/main";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/saastec-dashboard",
    component: Main,
    meta: {
      authRequired: true,
      hidden: true,
    },
    children: [
      {
        path: "/saastec-dashboard",
        name: "SaaSTEC",
        meta: {
          title: "SaaSTEC",
          cod: "V36SF0902A",
        },
        component: () => import("../views/dashboard/V36SF0902A.vue"),
      },
      {
        path: "/lojas",
        name: "Lojas",
        meta: {
          title: "Lojas",
          cod: "V36SF0905A",
        },
        component: () => import("../views/Lojas/V36SF0905A.vue"),
      },
      {
        path: "/colaboradores",
        name: "Colaboradores",
        meta: {
          title: "Colaboradores",
          cod: "V36SF0904A",
        },
        component: () => import("../views/Vendedores/V36SF0904A.vue"),
      },
      {
        path: "/produtos",
        name: "Produtos",
        meta: {
          title: "Produtos",
          cod: "V36SF0901B",
        },
        component: () => import("../views/produtos/V36SF0901B.vue"),
      },
      {
        path: "/organizacao-produtos",
        name: "Dashboard",
        meta: {
          title: "Organização de produtos",
          cod: "V36SF0901A",
        },
        component: () => import("../views/organizacaoProdutos/V36SF0901A.vue"),
      },
      {
        path: "/config/telas",
        name: "Config. Telas",
        meta: {
          title: "Configuração de Telas",
          cod: "V36SF0907A",
        },
        component: () => import("../views/config/telas/V36SF0907A.vue"),
      },
      {
        path: "/clientes/simplificado/",
        name: "Cliente simplificado",
        meta: {
          title: "Cadastro cliente",
          cod: "V36SF0903A",
        },
        component: () =>
          import("../views/Clientes/Cadastro/Simplificado/V36SF0903A.vue"),
      },
      {
        path: "/clientes/importacao",
        name: "Importação de clientes",
        meta: {
          title: "Importação de clientes",
          cod: "V36SF0901C",
        },
        component: () => 
          import("../views/Clientes/Cadastro/Importacao/V36SF0908A.vue"),        
      },   
      {
        path: "/medicos",
        name: "Medicos oftalmologista",
        meta: {
          title: "Medicos oftalmologista",
          cod: "V36SF0906A",
        },
        component: () => import("../views/medicos/V36SF0906A.vue"),
      },
      {
        path: "/importacao-produtos",
        name: "Importação de produtos",
        meta: {
          title: "Importação de produtos",
          cod: "V36SF0901C",
        },
        component: () => import("../views/importacaoProdutos/V36SF0901C.vue"),
      },
      {
        path: "/importacao-produtos-listagem",
        name: "Listagem da importação de produtos",
        meta: {
          title: "Listagem da importação de produtos",
          cod: "V36SF0901D",
        },
        component: () => import("../views/importacaoProdutosListagem/V36SF0901D.vue"),
      },      
      {
        path: "/cadastro-produtos",
        name: "Cadastro de produtos",
        meta: {
          title: "Cadastro de produtos",
          cod: "V36SF09010A",
        },
        component: () => import("../views/criacaoProdutos/V36SF09010A.vue"),
      },
      {
        path: "/canvas-produtos",
        name: "Canvas produto",
        meta: {
          title: "Canvas produto",
          cod: "V36SF0901D",
        },
        component: () => import("../views/canvasProdutos/V36SF0901D.vue"),
      },
      // {
      //   path: '/clientes/cadastro/pessoa-fisica',
      //   name: 'Cadastro completo pessoa fisica',
      //   meta: {
      //       title: 'Cad. completo pessoa física',
      //       cod: "V36SF0903B",
      //   },
      //   component: () => import('../views/Clientes/Cadastro/PessoaFisica/index.vue'),
      // },
      // {
      //   path: '/clientes/cadastro/pessoa-juridica',
      //   name: 'Cadastro completo pessoa juridica',
      //   meta: {
      //       title: 'Cadastro completo de pessoa jurídica',
      //       cod: "V36SF0903C",
      //   },
      //   component: () => import('../views/Clientes/Cadastro/PessoaJuridica/index.vue'),
      // },
      // {
      //   path: '/clientes/alterar',
      //   name: 'Alterar CPF/CNPJ do cliente',
      //   meta: {
      //       title: 'Alterar CPF/CNPJ do cliente',
      //       cod: "V36SF0903D",
      //   },
      //   component: () => import('../views/Clientes/Cadastro/Alterar/index.vue'),
      // },
    ],
  },

  {
    path: "*",
    redirect: "/404",
    hidden: true,
    component: Main,
    children: [
      {
        path: "/404", // Erro 404 de página não encontrada
        meta: { title: "404" },
        component: () => import("../views/404"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
