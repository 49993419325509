/* eslint-disable no-useless-escape */
export default {

  methods: {
    uuidv4() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16),
      );
    },

    async mensagemConsole (...args) {
      if (process.env.NODE_ENV === 'development') {
        console.log(...args);
      }
    },
  
    async mensagemConsoleErro (...args) {
      if (process.env.NODE_ENV === 'development') {
        console.error(...args);
      }
    },      

    trimUpper(val) {
      let campo = val;
      let retorno = val;
      try {
        retorno = campo.toString().trim().toUpperCase();
      } catch {
        retorno = val;
      }
      return retorno;
    },

    trimLower(val) {
      let campo = val;
      let retorno = val;
      try {
        retorno = campo.toString().trim().toLowerCase();
      } catch {
        retorno = val;
      }
      return retorno;
    },    

    getUF( dado ) {
      const uf = [
        { sigla: "AC", descricao: "Acre" },
        { sigla: "AL", descricao: "Alagoas" },
        { sigla: "AP", descricao: "Amapá" },
        { sigla: "AM", descricao: "Amazonas" },
        { sigla: "BA", descricao: "Bahia" },
        { sigla: "CE", descricao: "Ceará" },
        { sigla: "DF", descricao: "Distrito Federal" },
        { sigla: "ES", descricao: "Espírito Santo" },
        { sigla: "GO", descricao: "Goiás" },
        { sigla: "MA", descricao: "Maranhão" },
        { sigla: "MT", descricao: "Mato Grosso" },
        { sigla: "MS", descricao: "Mato Grosso do Sul" },
        { sigla: "MG", descricao: "Minas Gerais" },
        { sigla: "PA", descricao: "Pará" },
        { sigla: "PB", descricao: "Paraíba" },
        { sigla: "PR", descricao: "Paraná" },
        { sigla: "PE", descricao: "Pernambuco" },
        { sigla: "PI", descricao: "Piauí" },
        { sigla: "RJ", descricao: "Rio de Janeiro" },
        { sigla: "RN", descricao: "Rio Grande do Norte" },
        { sigla: "RS", descricao: "Rio Grande do Sul" },
        { sigla: "RO", descricao: "Rondônia" },
        { sigla: "RR", descricao: "Roraima" },
        { sigla: "SC", descricao: "Santa Catarina" },
        { sigla: "SP", descricao: "São Paulo" },
        { sigla: "SE", descricao: "Sergipe" },
        { sigla: "TO", descricao: "Tocantins" },
      ];
      
      let estado = uf.find(estado => estado.sigla === dado);
      
      return estado;
    },

    DataStrToDate( data ) {
      let dateObject;
      let formattedDate
      let posicao = "AKI#01";
      try {
        if (data) {
          // Extraindo dia, mês e ano da string
          posicao = "AKI#01";
          let day = data.substring(0, 2);
          posicao = "AKI#02";
          let month = data.substring(3, 5);
          posicao = "AKI#03";
          let year = data.substring(6, 10);

          // Criando uma string no formato YYYY-MM-DD
          posicao = "AKI#04";
          formattedDate = `${year}-${month}-${day}`;

          // Convertendo para um objeto Date
          posicao = "AKI#05";
          dateObject = new Date(formattedDate);        
          posicao = "AKI#06";
        } else {
          posicao = "AKI#07";
        }
      } catch(err) {
        this.mensagemConsole("DataStrToDate.ERRO", posicao, err);
      }
      return dateObject;
    },
  },
}