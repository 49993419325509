<template>
  <div style="height: 90vh; display: flex; flex-direction: column; justify-content: center">
    <div class="itens-centrais">
      <v-img 
        lazy-src="./assets/Under Constructions_Isometric.svg"
        max-height="150"
        max-width="250"
        src="./assets/Under Constructions_Isometric.svg"
        style="margin-bottom: 15px"
      />
      Ops! Ainda estamos trabalhando nesta página! <br>
      <span
        class="voltar"
        @click="goToDashboard()"
      > Voltar para a página principal </span>
    </div>
  </div>
</template>

<script>
import methods from './methods'
export default {
  data(){
    return {

    }
  },
  methods: {
    ...methods,
  },
}
</script>

<style lang="scss" src="./style.scss">

</style>