<template>
  <div>
    <v-dialog
      v-model="openFilter"
      width="1200"
      @click:outside="close"
    >
      <v-card
        v-if="carregando"
        elevation="0"
        class="cardCenteredLoadingDialog"
      >
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        />
      </v-card>
      <v-card v-else>
        <div style="display: flex">
          <v-card-title>{{ nome }}</v-card-title>
          <v-btn
            v-if="adicionar"
            color="primary"
            text
            :style="{ marginTop: '13px' }"
            @click="novoItem = !novoItem"
          >
            <v-icon>add</v-icon>
            Novo {{ nome }}
          </v-btn>
        </div>
        <div
          v-if="novoItem && adicionar"
          style="padding: 20px"
        >
          <adicionar-items
            v-if="nome !== 'Grupo'"
            :nome="nome"
            :items="items"
            @salvo="recarregaObj(ultimoFiltros)"
          />
          <adicionar-grupo v-else />
        </div>
        <div v-if="filtro">
          <multiline-filter
            :opcoes-tipo="campos"
            :lista-traducao="$t('MULTIFILTER')"
            :qt-maxima="qtMaxima"
            :analitico-sintetico="true"
            :numero-aplicacao="$cd_sist"
            @ok="mountObj"
            @nok="erroBuscaPorFiltro"
          />
        </div>
        <v-container>
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="5"
            @click:row="sendValue"
          />
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import adicionarItems from "../adicionarItems/";
import adicionarGrupo from "../adicionarItems/adicionarGrupo/";
export default {
  name: "DialogoLocaliza",
  components: { adicionarItems, adicionarGrupo },
  model: {
    prop: "abreFiltro",
    event: "change",
  },
  props: {
    abreFiltro: Boolean,
    filtro: {
      // Utilizar a opção do multiFiltro
      type: Boolean,
      required: true,
    },
    sp: {
      // SP que será feita a req
      type: String,
      required: true,
    },
    campos: {
      // Campos que seram utilizados para o multifiltro
      type: Array,
      required: true,
    },
    nome: {
      // Nome do Dialog
      type: String,
      required: true,
    },
    adicionar: {
      type: Boolean, // adicionar nova informacao
      default: false,
    },
  },
  data() {
    return {
      filtroPor: "",
      pesquisa: "",
      carregando: false,
      items: [],
      headers: [],
      qtMaxima: 10,
      ultimoFiltros: [],
      novoItem: false,
    };
  },
  computed: {
    openFilter() {
      return this.abreFiltro;
    },
  },
  watch: {
    openFilter() {
      if (this.openFilter) {
        this.mountObj({ quantidade: 25, filtros: [{}] });
      }
    },
  },
  mounted() {
    if (this.openFilter) {
      this.mountObj(this.ultimoFiltros);
    }
    this.novoItem = false;
  },
  methods: {
    recarregaObj(e) {
      this.carregando = true;
      setTimeout(() => {
        this.mountObj(e);
      }, 300);
    },
    async mountObj(e) {
      e.filtros.map((f) => {
        if (f.parametro === "CE001_NR_CPFCNPJ") {
          f.valor1 = this.unmaskCpf(f.valor1);
        }
        return f;
      });
      this.carregando = true;
      this.ultimoFiltros = e;
      if (e.length !== 0) {
        try {
          let itemsDB = await this.chamaMulti(this.sp, e);
          itemsDB = itemsDB.database;
          this.items = itemsDB;
          this.headers = this.campos.map((c, i) => {
            if (i === 0)
              return {
                text: c.text,
                sortable: true,
                value: c.value.toLowerCase(),
                width: 120,
                align: "right",
              };
            else
              return {
                text: c.text,
                value: c.value.toLowerCase(),
              };
          });
        } catch (err) {
          this.notification().error({erro: "Algo deu errado durante a pesquisa"});
        } finally {
          this.carregando = false;
        }
      } else {
        e = { quantidade: 25, filtros: [{}] };
        this.mountObj(e);
      }
    },
    sendValue(e) {
      this.$emit("returnValue", e);
      this.close();
    },
    erroBuscaPorFiltro() {
      this.notification().error({erro: "Erro ao filtrar"});
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>

<style>
.cardCenteredLoadingDialog {
  height: 400px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.v-expansion-panel:before {
  box-shadow: none;
}
</style>