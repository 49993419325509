export default {
  methods: {
    maskPorcentagem(numero){
      const porcentagem = (numero * 100).toString()
      return porcentagem
    },

    maskCep(numero){
      var cep = numero.toString().slice(0, 5) + '-' + numero.toString().slice(5, 8)

      return cep
    },

    maskTelefone(ddd, telefone) {
      if(telefone && ddd !== undefined){
      var numero = '(' + ddd.toString() + ') '
      if(telefone.toString().length == 9)
        numero += telefone.toString().slice(0,5) + '-' + telefone.toString().slice(5, 9)
      else
        numero += telefone.toString().slice(0,4) + '-' + telefone.toString().slice(4, 8)
      }
      return numero
    },

    maskCPFCNPJ(numero){
      var cpfCnpj;
      if (numero.toString().length == 11)
        cpfCnpj = numero.toString().slice(0,3) +'.'+numero.toString().slice(3,6) +'.'+ numero.toString().slice(6,9) + '-' + numero.toString().slice(9,11)
      else
        cpfCnpj = numero.toString().slice(0,2) +'.'+numero.toString().slice(2,5) +'.'+ numero.toString().slice(5,8) + '/' + numero.toString().slice(8,12) + '-' + numero.toString().slice(12,14)
      
      return cpfCnpj
    },

    maskData(data, AM = false){
      if(data){
        const novaData = data.toString()
        const ano = novaData.substr(0, 4)
        const mes = novaData.substr(4, 2)
        const dia = novaData.substr(6, 2)
        if(!AM)
          return `${dia}/${mes}/${ano}`
        else
          return `${mes}/${ano}`
      }
      else
        return null
    },

    maskDataHora(data, AM = false){
      if(data){
        const novaData = data.toString()
        const ano = novaData.substr(0, 4)
        const mes = novaData.substr(4, 2)
        const dia = novaData.substr(6, 2)

        const hora = novaData.substr(9, 2)
        const minuto = novaData.substr(12, 2)
        const segundo = novaData.substr(15, 2)

        if(!AM)
          return `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`
        else
          return `${mes}/${ano} ${hora}:${minuto}`
      }
      else
        return null
    },    
    
    mascaraLoja(loja) {
      const mascaraLoja = `${loja.toString().substr(0, (loja.toString().length - 1))}-${loja.toString().substr(loja.toString().length - 1, (loja.toString().length))}`
      return mascaraLoja
    },
  },
}