import { mapState, mapActions } from 'vuex'

export default {
 computed: {
  ...mapState(['validacaoForm', 'formValido']),
 },
  methods: {
    ...mapActions(['vuexSet']),
  async validateForm(){
    const novoValor = this.validacaoForm + 1
    await this.vuexSet({ value: novoValor, stateName: 'validacaoForm' })
    return this.formValido
  },
},
}