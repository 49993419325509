import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState(['drawerProdutos']),
  },
  methods: {
    ...mapActions(['vuexSet']),
    openMenuProdutos() {
      this.vuexSet({ value: true, stateName: 'drawerProdutos' })
    },
    closeMenuProdutos() {
      this.vuexSet({ value: false, stateName: 'drawerProdutos' })
    },
  },
}